import { defineStore } from "pinia";
import axios from "axios";
import { toRaw } from "vue";
import { useDashboardStore } from './dashboardStore';

export const useGraphPerformanceStore = defineStore('graphPerformance', {
    state: () => ({
        dataSet: {
            selected_dates: JSON.parse(localStorage.getItem('dashboardSelectedDates')),
            dashboardStore: useDashboardStore(),
            yearPrevious: 0,
            yearCurrent: 0,
            ConversionPrevious: [],
            ConversionCurrent: [],
            SessionsPrevious: [],
            SessionsCurrent: [],
            ImpressionsPrevious: [],
            ImpressionsCurrent: [],
            ConversionsPrevious: [],
            ConversionsCurrent: []
        },
    }),
    getters: {
        additionalOptionsSessions() {
            return {
                colors: ['#C8B9EC', '#20016c'],
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#20016c'
                        },
                        labels: {
                            style: {
                                color: '#fff',
                            }
                        },
                        title: {
                            text: ''
                        }
                    }
                ]
            }
        },
        additionalOptionsImpressions() {
            return {
                colors: ['#FDC8D2', '#fa5c7c'],
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#fa5c7c'
                        },
                        labels: {
                            style: {
                                color: '#fff',
                            }
                        },
                        title: {
                            text: ''
                        }
                    }
                ]
            }
        },
        additionalOptionsConversions() {
            return {
                colors: ['#B4DAD1', '#77d0ba'],
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#77d0ba'
                        },
                        labels: {
                            style: {
                                color: '#fff',
                            }
                        },
                        title: {
                            text: ''
                        }
                    }
                ]
            }
        },
        chartSessionsSeries(state) {
            return [
                {
                    type: 'column',
                    name: 'Sessions ' + state.yearPrevious,
                    data: toRaw(state.SessionsPrevious)
                },
                {
                    type: 'column',
                    name: 'Sessions ' + state.yearCurrent,
                    data: toRaw(state.SessionsCurrent)
                }
            ]
        },
        chartImpressionsSeries(state) {
            return [
                {
                    type: 'column',
                    name: 'Impressions ' + state.yearPrevious,
                    data: toRaw(state.ImpressionsPrevious)
                },
                {
                    type: 'column',
                    name: 'Impressions ' + state.yearCurrent,
                    data: toRaw(state.ImpressionsCurrent)
                }
            ]
        },
        chartConversionsSeries(state) {
            return [
                {
                    type: 'column',
                    name: 'Conversions ' + state.yearPrevious,
                    data: toRaw(state.ConversionsPrevious)
                },
                {
                    type: 'column',
                    name: 'Conversions ' + state.yearCurrent,
                    data: toRaw(state.ConversionsCurrent)
                }
            ]
        }
    },
    actions: {
        async fetchDataSessions(selectedMarket, selectedProject) {
            const options = {
                "market_id": selectedMarket,
                "project_id": selectedProject,
                "date_range_start": this.dataSet.selected_dates.from,
                "date_range_end": this.dataSet.selected_dates.to,
                "filter": this.dataSet.dashboardStore.getFilter
            }

            const response = await axios.post('vuejs/performance_chart_sessions', options, { withCredentials: true });

            this.SessionsPrevious = response.data.sessions_previous;
            this.SessionsCurrent = response.data.sessions_current;
            this.yearPrevious = response.data.previous_year;
            this.yearCurrent = response.data.current_year;

            return response.data.success;
        },
        async fetchDataImpressions(selectedMarket, selectedProject) {
            const options = {
                "market_id": selectedMarket,
                "project_id": selectedProject,
                "date_range_start": this.dataSet.selected_dates.from,
                "date_range_end": this.dataSet.selected_dates.to,
                "filter": this.dataSet.dashboardStore.getFilter
            }

            const response = await axios.post('vuejs/performance_chart_impressions', options, { withCredentials: true });

            this.ImpressionsPrevious = response.data.impressions_previous;
            this.ImpressionsCurrent = response.data.impressions_current;
            this.yearPrevious = response.data.previous_year;
            this.yearCurrent = response.data.current_year;

            return response.data.success;
        },
        async fetchDataConversions(selectedMarket, selectedProject) {
            const options = {
                "market_id": selectedMarket,
                "project_id": selectedProject,
                "date_range_start": this.dataSet.selected_dates.from,
                "date_range_end": this.dataSet.selected_dates.to,
                "filter": this.dataSet.dashboardStore.getFilter
            }

            const response = await axios.post('vuejs/performance_chart_conversions', options, { withCredentials: true });

            this.ConversionsPrevious = response.data.conversions_previous;
            this.ConversionsCurrent = response.data.conversions_current;
            this.yearPrevious = response.data.previous_year;
            this.yearCurrent = response.data.current_year;

            return response.data.success;
        }
    },
    persist: false
});