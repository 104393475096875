<template>
	<header class="bg-red">
	  	<div class="flex">
			<FilteredSelect
				:options="projects"
				:selected="selectedProject"
				width="280px"
				@changeItem="changeProject"
				data-cy="tm_select_project"
			></FilteredSelect>

			<FilteredSelect
				:options="markets"
				:selected="selectedMarket"
				width="140px"
				@changeItem="changeMarket"
				data-cy="tm_select_market"
			></FilteredSelect>
		</div>
		<Profile/>
	</header>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import FilteredSelect from "../forms/FilteredSelect.vue";
import Profile from '../profile/profile.vue';
import { useMarketStore } from "@/store/global/market";
import { useAuthStore } from "@/store/global/auth";
import { useKeywordsStore } from "@/store/visibility/keywords";
import { storeToRefs } from 'pinia';

const KeywordsStore = useKeywordsStore();
const marketStore = useMarketStore();
const authStore = useAuthStore()
const { projects, selectedProject , markets, selectedMarket } = storeToRefs(marketStore)
window.dataLayer  = window.dataLayer || [];
function changeProject(id) {
	marketStore.selectProject(id);
	KeywordsStore.setLoading(true);
	location.reload();
}

function changeMarket(id) {
	marketStore.selectMarket(id);
	KeywordsStore.setLoading(true);
	location.reload();
}

if (typeof(selectedProject.value) == 'object') {

	let key1 = '_vts' in selectedProject.value;
	let key2 = 'isTrusted' in selectedProject.value;

	if (key1 || key2) {

		selectedProject.value = 'na';
		location.reload();
	}
}

if (typeof(selectedMarket.value) == 'object' && selectedMarket.value != null) {

let key1 = '_vts' in selectedProject.value;
let key2 = 'isTrusted' in selectedProject.value;

if (key1 || key2) {

	selectedProject.value = null;
	location.reload();
}
}
onMounted(async () => {
	await marketStore.fetchData();
	window.dataLayer.push({ "selected" : {'selected_project' :  selectedProject.value,"selected_market" : selectedMarket.value}});


	if (!authStore.isLoggedIn) {
		marketStore.selectedMarket = null
        localStorage.removeItem('kpi');
		localStorage.removeItem('profile');
		localStorage.removeItem('market');
		localStorage.removeItem('auth');
	}
})

</script>