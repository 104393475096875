// ####################################################################################################################
// # Copyright (C) 2023 Semactic SRL - All Rights Reserved
// # Unauthorized copying of this file, or its contents, even partially, through any medium is strictly prohibited.
// # Proprietary and confidential. Semactic SRL is the sole owner of this code.
// # Written by Fourneaux Thibaut (thibaut.fourneaux@semactic.com) under Semactic SEO Software Project.
// ####################################################################################################################

import { defineStore } from 'pinia';
import axios from 'axios';
import { orderArray, setBeforeAfter, checkForChange } from '@/utils/arrays';
import { useNotificationStore } from "@/store/global/notification";
import { decompressJson } from '@/utils/unzip';

export const useRecommendationsStore = defineStore('recommendations', {
    state: () => ({
        recommendations: [],
        recommendationsClone: [],
        tasks: [],
        viewPreferences: 'grid',
        loading: true,
        filterData: {}
    }),
    getters: {
        unlisted(state) {
            return state.recommendations.filter(item => item.status === 0)
        },
        todo(state) {
            return state.recommendations.filter(item => item.status === 1)
        },
        doing(state) {
            return state.recommendations.filter(item => item.status === 2)
        },
        done(state) {
            return state.recommendations.filter(item => item.status === 3)
        },
        abandoned(state) {
            return state.recommendations.filter(item => item.status === 4)
        },
        users: (state) => {
            const allUsers = state.recommendations
                .flatMap(rec => rec.users ? rec.users : [])
                .filter(user => user != null); // This line filters out null or undefined users
            const uniqueUsers = Array.from(new Set(allUsers.map(user => JSON.stringify(user))))
                .map(str => JSON.parse(str));
            return uniqueUsers;
        }
    },
    actions: {
        async fetchData(selectedMarket) {

            this.loading = true;

            const response = await axios.post('vuejs/get_recommendations_lv1', {
                "market_id": selectedMarket,
            }, { withCredentials: true });

            const deepClone = JSON.parse(JSON.stringify(response.data.data));
            let recommendationsByStatus = this.splitRecommendationsByStatus(response.data.data);
            let didAbandonTasks = false;
            let didImplementTasks = false;

            if (recommendationsByStatus['0'] === undefined) {
                recommendationsByStatus['0'] = [];
            }
            else {

                recommendationsByStatus['0'].forEach(pendingReco => {

                    if (pendingReco.abandoned_count === pendingReco.total_tasks) {

                    this.updateStatus(pendingReco.id, 4);
                    pendingReco.status = 4;
                    didAbandonTasks = true;
                    }
                    else if (pendingReco.implemented_count === pendingReco.total_tasks) {

                    this.updateStatus(pendingReco.id, 3);
                    pendingReco.status = 3;
                    didImplementTasks = true;
                    }
                });

                recommendationsByStatus = this.splitRecommendationsByStatus(Object.values(recommendationsByStatus).flat());
            }

            // separate new recommendations from old ones in the unlisted status
            const newRecommendations = (recommendationsByStatus['0'] || []).filter(item => item.reco_id_before === null && item.reco_id_after === null);
            const oldRecommendations = (recommendationsByStatus['0'] || []).filter(item => item.reco_id_before !== null || item.reco_id_after !== null);

            // set the reco_id_before and reco_id_after properties for each recommendation
            const setNewRecommendationsId = setBeforeAfter(newRecommendations)

            // merge the new recommendations with the old ones and set id on linked elements
            if (oldRecommendations.length > 0 && setNewRecommendationsId.length > 0) {

                const sortedOldRecommendations = orderArray(oldRecommendations);
                setNewRecommendationsId[setNewRecommendationsId.length - 1].reco_id_after = sortedOldRecommendations[0].id;
                sortedOldRecommendations[0].reco_id_before = setNewRecommendationsId[setNewRecommendationsId.length - 1].id;
            }

            if (setNewRecommendationsId.length > 0) {

                // update new recommendations in the database
                const updateNewRecommendations = oldRecommendations.length > 0 ? [...setNewRecommendationsId, oldRecommendations[0]] : [...setNewRecommendationsId];
                this.updateOrder(updateNewRecommendations);
            }

            recommendationsByStatus['0'] = [...setNewRecommendationsId, ...oldRecommendations];

            if (didAbandonTasks == true) {
                recommendationsByStatus['4'] = setBeforeAfter(recommendationsByStatus['4']);
                this.updateOrder(recommendationsByStatus['4']);
            }

            if (didImplementTasks == true) {
                recommendationsByStatus['3'] = setBeforeAfter(recommendationsByStatus['3']);
                this.updateOrder(recommendationsByStatus['3']);
            }

            for (let i = 0; i < 4; i++) {
                recommendationsByStatus[i] = orderArray(recommendationsByStatus[i] || []);
            }

            let finalRecommendations = Object.values(recommendationsByStatus).flat();
            let deepDiferences = checkForChange(deepClone, finalRecommendations);

            if (deepDiferences.length > 0) {
                this.updateOrder(checkForChange(deepClone, finalRecommendations));
            }

            this.loading = false;
            const groupedRecommendations = this.splitRecommendationsByStatus([...finalRecommendations])
            let sortedRecommendations = [];
            for(let key in groupedRecommendations) {
                sortedRecommendations = [ ...sortedRecommendations, ...groupedRecommendations[key].sort(this.customSort) ]
            }
            this.recommendations = [...sortedRecommendations];
            this.recommendationsClone = [...sortedRecommendations];
        },
        async fetchTasks(selectedMarket) {

            this.loading = true;

            const response = await axios.post('vuejs/get_recommendations_tasks', {
                "market_id": selectedMarket,
            }, { withCredentials: true }).then(response => {

                var base64String = response.data.data["base64(zip(o))"];
                if (typeof base64String === 'string') {
                    var decompressedData = decompressJson(base64String);
                    console.log(decompressedData);
                } else {
                    console.error("The extracted data is not a string:", base64String);
                }
                this.tasks = decompressedData;
                this.loading = false;
            });

            return this.tasks;
        },
        async getImplementationAndDates(groupId) {

            const response = await axios.post('vuejs/get_graph_implementations', {
                "group_id": groupId,
            }, { withCredentials: true });

            if (response.data) {

                this.implementations = response.data.implementations;
                this.graphRange.from = response.data.date_range_start;
                this.graphRange.to   = response.data.date_range_end;
            }
        },
        async setImplementation(recoId) {
            const response = await axios.post('vuejs/set_reco_implemented', {
                "reco_id": recoId,
            }, { withCredentials: true });
        },
        async undoImplementation(recoId) {
            const response = await axios.post('vuejs/undo_reco_implemented', {
                "reco_id": recoId,
            }, { withCredentials: true });
        },
        async setValidation(recoId) {
            const response = await axios.post('vuejs/set_reco_validated', {
                "reco_id": recoId,
            }, { withCredentials: true });
        },
        async undoValidation(recoId) {
            const response = await axios.post('vuejs/undo_reco_validated', {
                "reco_id": recoId,
            }, { withCredentials: true });
        },
        async setAbandon(recoId) {
            const response = await axios.post('vuejs/set_reco_abandoned', {
                "reco_id": recoId,
            }, { withCredentials: true });
        },
        async undoAbandon(recoId) {
            const response = await axios.post('vuejs/undo_reco_abandoned', {
                "reco_id": recoId,
            }, { withCredentials: true });
        },
        async updateMessage(recoId, message) {
            const response = await axios.post('vuejs/update_reco_message', {
                "reco_id": recoId,
                "message": message,
            }, { withCredentials: true });
        },
        // status
        async updateStatus(recoId, destination) {

            const response = await axios.post('vuejs/set_reco_lv1_status', {
                "reco_id": recoId,
                "status": destination,
            }, { withCredentials: true });
        },
        changeStatus(recoId, destination, dropResult, t) {
            const reco = this.recommendations.find(item => item.id === recoId);
            let updatedRecommendations = []
            // update column
            if (reco) {
                if (destination === 0 && (reco.abandoned_count === reco.total_tasks || reco.implemented_count === reco.total_tasks)) {
                    useNotificationStore().open('info', t('task_drag_info'));
                    return 
                } else {
                    this.setOrderWhenMovingCard('remove', recoId, reco.status).forEach(element => {
                        this.updateRecommendationPositionArr(updatedRecommendations, element);
                    });                    
                    reco.status = destination;
                    this.changeOrder(destination, dropResult, recoId, updatedRecommendations, true);
                }
            }
        },
        changeCardStatus(recoId, destination) {
                
            const reco = this.recommendations.find(item => item.id === recoId);
            reco.status = destination;
            this.updateStatus(recoId, destination);
        },
        // order
        async updateOrder(recommendations) {
            // this.loading = true;

            const updateOrderArray = recommendations.map(item => ({
                id: item.id,
                reco_id_before: item.reco_id_before,
                reco_id_after: item.reco_id_after,
            }));

            const response = await axios.post('vuejs/update_recommendations_order', {
                "data": updateOrderArray
            }, {
                withCredentials: true,
            });
            // this.loading = false;
        },
        async changeOrder(status, dropResult, recoId = null, updatedRecommendations = [], changeStatus = false) {
            const { removedIndex, addedIndex } = dropResult;
            const recommendationsByStatus = await this.splitRecommendationsByStatus(this.recommendations);
            // moved from another column
            if (addedIndex != null && recoId != null) {

                const currectRecoIndex = recommendationsByStatus[status].findIndex(item => item.id === recoId)

                const [draggedItem] = recommendationsByStatus[status].splice(currectRecoIndex, 1);
                recommendationsByStatus[status].splice(addedIndex, 0, draggedItem);
                this.recommendations = Object.values(recommendationsByStatus).flat();

                this.setOrderWhenMovingCard('add', recoId, status).forEach(element => {
                    this.updateRecommendationPositionArr(updatedRecommendations, element);
                });
            }

            // moved within the same column
            else if (removedIndex != null && addedIndex != null) {
                recoId = recommendationsByStatus[status][removedIndex].id;
                this.setOrderWhenMovingCard('remove', recoId, status).forEach(element => {
                    this.updateRecommendationPositionArr(updatedRecommendations, element);
                });

                const [draggedItem] = recommendationsByStatus[status].splice(removedIndex, 1);
                recommendationsByStatus[status].splice(addedIndex, 0, draggedItem);
                this.recommendations = Object.values(recommendationsByStatus).flat();

                this.setOrderWhenMovingCard('add', recoId, status).forEach(element => {
                    this.updateRecommendationPositionArr(updatedRecommendations, element);
                });
            }

            // update order
            if (updatedRecommendations.length > 0) {

                this.updateOrder(updatedRecommendations);
            }

            if (changeStatus == true) {
                let reco = this.recommendations.find(item => item.id === recoId);
                reco.status = status;
                this.updateStatus(recoId, status);
            }
        },
        customSort(a, b) {
            if (a.reco_id_before === 0) return -1;
            if (b.reco_id_before === 0) return 1;
            if (a.reco_id_after === 0) return 1;
            if (b.reco_id_after === 0) return -1;
            if (a.reco_id_after !== b.id) return 1;
            if (b.reco_id_before !== a.id) return -1;
            return a.reco_id_after - b.id;
        },    
        setOrderWhenMovingCard(cases, recoId, status) {

            const recommendationsByStatus = this.splitRecommendationsByStatus(this.recommendations);
            const recoIndex = recommendationsByStatus[status].findIndex(item => item.id === recoId);
            const reco = recommendationsByStatus[status][recoIndex];
            const changedItem = [];

            switch (cases) {

                case 'remove':

                    // set previous item
                    if(recommendationsByStatus[status][recoIndex - 1]) {
                        recommendationsByStatus[status][recoIndex - 1].reco_id_after = reco.reco_id_after;
                        changedItem.push(recommendationsByStatus[status][recoIndex - 1]);
                    }
                    // set next item
                    if (recommendationsByStatus[status][recoIndex + 1]) {
                        recommendationsByStatus[status][recoIndex + 1].reco_id_before = reco.reco_id_before;
                        changedItem.push(recommendationsByStatus[status][recoIndex + 1]);
                    }

                    // reset current item
                    reco.reco_id_before = 0;
                    reco.reco_id_after = 0;

                    changedItem.push(reco);

                    return changedItem;

                case 'add':

                    // set previous item
                    if(recommendationsByStatus[status][recoIndex - 1]) {
                        recommendationsByStatus[status][recoIndex - 1].reco_id_after = reco.id;
                        reco.reco_id_before = recommendationsByStatus[status][recoIndex - 1].id;
                        changedItem.push(recommendationsByStatus[status][recoIndex - 1]);
                    }
                    else {
                        reco.reco_id_before = 0;
                    }

                    // set next item
                    if (recommendationsByStatus[status][recoIndex + 1]) {
                        recommendationsByStatus[status][recoIndex + 1].reco_id_before = reco.id;
                        reco.reco_id_after = recommendationsByStatus[status][recoIndex + 1].id;
                        changedItem.push(recommendationsByStatus[status][recoIndex + 1]);
                    }
                    else {
                        reco.reco_id_after = 0;
                    }

                    // add current item with the new values
                    changedItem.push(reco);
                    return changedItem;
            }
        },
        updateRecommendationPositionArr(arr, element) {

            const index = arr.findIndex(el => el.id === element.id);
            if (index !== -1) {

              arr[index] = element;
            }
            else {

              arr.push(element);
            }
        },
        splitRecommendationsByStatus(recommendations) {
            return recommendations.reduce((result, item) => {

                if (!result[item.status]) {
                    result[item.status] = [];
                }

                result[item.status].push(item);
                return result;
            }, {});
        },
        async updateSeoTitle(recoId, seoTitle) {
            const response = await axios.post('vuejs/update_reco_seo_title', {
                "reco_id": recoId,
                "seo_title": seoTitle,
            }, { withCredentials: true });

            // if (response.data.success) {
                this.recommendations.filter(recommendations => recommendations.id == recoId)[0].seo_title = seoTitle;
            // }
        },
        async updateMetaDescription(recoId, metaDescription) {
            console.log("UpdateMeta2")
            const response = await axios.post('vuejs/update_reco_meta_description', {
                "reco_id": recoId,
                "meta_description": metaDescription,
            }, { withCredentials: true });
            // if (response.data.success) {
                this.recommendations.filter(recommendations => recommendations.id == recoId)[0].meta_description = metaDescription;
            // }
        },
        async updateDescription(recoId, description) {
            const response = await axios.post('vuejs/update_reco_description', {
                "reco_id": recoId,
                "description": description,
            }, { withCredentials: true });
            // if (response.data.success) {
                this.recommendations.filter(recommendations => recommendations.id == recoId)[0].description = description;
            // }
        },

        async createRecommendations(recommendations, market_id, task_type, tMessage = null) {
            console.log(recommendations)
            const response = await axios.post('vuejs/create_recommendations', {
                "recommendations": recommendations,
                "market_id": market_id,
                "type_reco": task_type,
            }, { withCredentials: true }).then((response) => {
                this.fetchData(market_id)
                console.log(response)
            if (response.data.success == true) {
                useNotificationStore().open('success', 'Recommendation(s) created');
            }
            else {
                useNotificationStore().open('error', `${response.data.message}`)
            }
            });
        },

        async updateDeadline(recoId, deadline) {
            const response = await axios.post('vuejs/update_reco_deadline', {
                "reco_id": recoId,
                "deadline": deadline,
            }, { withCredentials: true });
            // if (response.data.success) {
                // this.recommendations.filter(recommendations => recommendations.id == recoId)[0].deadline = deadline;
            // }
        },
        async updateDeadlineTask(taskId, deadline) {
            const response = await axios.post('vuejs/update_task_deadline', {
                "task_id": taskId,
                "deadline": deadline,
            }, { withCredentials: true });
        },
        async generateRecommendations({
            market_id, 
            labels_id, 
            force = 1, 
            onpage_reco = 1, 
            new_content_reco = 1, 
            limit_onpage = 10, 
            limit_new_content = 10, 
            backlink_reco = 2,
            type_reco = 'definitive',
            keyword_id = null,
            page_url = null,
            tMessage = null
        }) {
            // labels_id is an array
            if (tMessage === null) {
                useNotificationStore().open('info', 'Generating recommendations.. It can take up to 10 minutes, you will get a notification once it is done.');
                tMessage = {
                    success: 'Recommendations generated successfully',
                    error: 'An error occurred while generating recommendations'
                };
            }
            else {
                useNotificationStore().open('info', tMessage.waiting);
            }

            try {
                const response = await axios.post('vuejs/generate_recommendations', {
                    "market_id": market_id,
                    "labels_id": labels_id,
                    "force": force,
                    "onpage_reco": onpage_reco,
                    "new_content_reco": new_content_reco,
                    "limit_onpage": limit_onpage,
                    "limit_new_content": limit_new_content,
                    "backlink_reco": backlink_reco,
                    "type_reco": type_reco,
                    "keyword_id": keyword_id,
                    "page_url": page_url,
                }, { withCredentials: true });

                if (response.data.success) {
                    let number_of_recommendations = response.data.number_of_recommendations || 0;

                    if (number_of_recommendations === 0) {
                        console.log(tMessage.noReco);
                        useNotificationStore().open('info', tMessage.noReco);
                    }
                    else {
                        useNotificationStore().open('success', `
${number_of_recommendations} ${tMessage.success}`);
                    }


                        this.fetchData(market_id);
                        return false;
                    } else {
                        useNotificationStore().open('error', `${response.data.message}`);
                        return false;
                    }
                } catch (error) {
                    // Gérer l'erreur
                    useNotificationStore().open('error', tMessage.error);
                    console.error(error);
                    return false;
                }
        },
        applyFilter(source, filter) {
            if (!filter || !filter.item) {
                return source;
            }

            const queryValue = filter.item.name ? filter.item.name.toLowerCase() : '';
            const querySelector = filter.item.key;

            if (!queryValue && !querySelector) {
                return source;
            }
            let data = null
            switch (querySelector) {
                case 'type':
                    data = source.filter(item => item.task_type.toLowerCase().includes(queryValue))
                    return data.length ? data : [];
                case 'title':
                    data = source.filter(item => item.title.toLowerCase().includes(queryValue))
                    return data.length ? data : [];
                case 'url':
                    data = source.filter(item => item.page_url[0] && item.page_url[0].toLowerCase().includes(queryValue))
                    return data.length ? data : [];
                case 'keyword':
                    data = source.filter(item =>
                        item.keyword_data[0] &&
                        item.keyword_data[0].keyword &&
                        item.keyword_data[0].keyword.toLowerCase().includes(queryValue)
                    );
                    return data.length ? data : [];
                case 'user':
                    const [queryFirstName, queryLastName] = queryValue.split(' - ').map(name => name.trim().toLowerCase());
                    data = source.filter(item =>
                        Array.isArray(item.users) && item.users.some(user =>
                            user &&
                            user.first_name?.toLowerCase() === queryFirstName &&
                            user.last_name?.toLowerCase() === queryLastName
                        )
                    );
                    return data.length ? data : [];
                default:
                    return source.length ? source : [];
            }
        },
        setFilterData(newFilterData) {
            this.filterData = newFilterData;
            this.recommendations = [...this.recommendationsClone]
            const recommendationsByStatus = this.splitRecommendationsByStatus(this.recommendations);
            const result = {}
            for (let key in recommendationsByStatus) {
                result[key] = this.applyFilter(recommendationsByStatus[key], this.filterData);
            }
            this.recommendations = Object.values(result).flat();
        },
    },
    persist: true,
});