// ####################################################################################################################
// # Copyright (C) 2023 Semactic SRL - All Rights Reserved
// # Unauthorized copying of this file, or its contents, even partially, through any medium is strictly prohibited.
// # Proprietary and confidential. Semactic SRL is the sole owner of this code.
// # Written by Fourneaux Thibaut (thibaut.fourneaux@semactic.com) under Semactic SEO Software Project.
// ####################################################################################################################

import { defineStore } from 'pinia';
import axios from 'axios';

export const useRecommendationDetailStore = defineStore('recommendation-detail', {
    state: () => ({
        recommendation: {},
        graphRange: {
            from: null,
            to: null,
        },
        implementations: [],
        keyword: {
            id: null,
            name: '',
            volume: null,
            potential: null,
            position: null,
        },
        loading: true,
    }),
    actions: {
        async fetchData(recoId, marketId = null) {
            
            this.loading = true
            this.recommendation = {}
            this.keyword = {
                id: null,
                name: '',
                volume: null,
                potential: null,
                position: null,
            }
            if (recoId == 0 && marketId) {

                const response = await axios.post('vuejs/get_recommendations_lv1_detail', {
                    "market_id": marketId,
                }, { withCredentials: true });

                this.recommendation = response.data.data;
                this.keyword.id = this.recommendation.kw_id != 0 ? this.recommendation.kw_id : null;
            }
            else {

                const response = await axios.post('vuejs/get_recommendations_lv1_detail', {
                    "reco_id": recoId,
                }, { withCredentials: true });
                
                this.recommendation = response.data.data;
                this.keyword.id = this.recommendation.kw_id != 0 ? this.recommendation.kw_id : null;
            }

            this.getRecommendationKeyword(this.keyword.id);
            this.loading = false;

        },
        async getImplementationAndDates(groupId) {

            this.implementations = [];

            const response = await axios.post('vuejs/get_graph_implementations', {
                "group_id": groupId,
            }, { withCredentials: true });

            this.implementations = response.data.data.implementation || [];
            this.graphRange.from = response.data.data.date_range_start || null;
            this.graphRange.to   = response.data.data.date_range_end || null;
        },
        async getRecommendationKeyword(keywordId) {
            
            const response = await axios.post('vuejs/get_reco_keyword', {
                "keyword_id": keywordId,
            }, { withCredentials: true });

            this.keyword = {
                id: keywordId,
                name: response.data.data.keyword,
                volume: response.data.data.volume,
                potential: response.data.data.seo_potential,
                position: response.data.data.client_position,
            };
        },
        async setPageUrl(recoId, url, doneToCheck) { 

            const data = {}

            const response = await axios.post('vuejs/set_reco_page_url', {
                "reco_id": recoId,
                "url": url,
                "done_to_check": doneToCheck
            }, { withCredentials: true })
            .then(function(response) {

                data.success = true
                data.data = response.data
            })
            .catch(function(error) {
                
                data.success = false;
            });

            if (data.success) {
                let reco = this.recommendation.tasks.filter(task => task.id == recoId)[0];

                reco.page_url = url;
                reco.validated_type = 'auto';
                reco.implemented = true;
            }

            return data;
        },
        async setImplementation(recoId) {
            const response = await axios.post('vuejs/set_reco_implemented', {
                "reco_id": recoId,
            }, { withCredentials: true });

            if (response.data.success) {
                this.recommendation.tasks.filter(task => task.id == recoId)[0].implemented = true;
                this.recommendation.tasks.filter(task => task.id == recoId)[0].validated_type = 'manual';
            }
        },
        async undoImplementation(recoId) {
            const response = await axios.post('vuejs/undo_reco_implemented', {
                "reco_id": recoId,
            }, { withCredentials: true });

            if (response.data.success) {
                this.recommendation.tasks.filter(task => task.id == recoId)[0].implemented = false;
            }
        },
        async setValidation(recoId) {
            const response = await axios.post('vuejs/set_reco_validated', {
                "reco_id": recoId,
            }, { withCredentials: true });

            if (response.data.success) {
                this.recommendation.tasks.filter(task => task.id == recoId)[0].validated = true;
            }
        },
        async undoValidation(recoId) {
            const response = await axios.post('vuejs/undo_reco_validated', {
                "reco_id": recoId,
            }, { withCredentials: true });

            if (response.data.success) {
                this.recommendation.tasks.filter(task => task.id == recoId)[0].validated = false;
            }
        },
        async setAbandonment(recoId) {
            const response = await axios.post('vuejs/set_reco_abandoned', {
                "reco_id": recoId,
            }, { withCredentials: true });

            if (response.data.success) {
                this.recommendation.tasks.filter(task => task.id == recoId)[0].abandoned = true;
            }
        },
        async undoAbandonment(recoId) {
            const response = await axios.post('vuejs/undo_reco_abandoned', {
                "reco_id": recoId,
            }, { withCredentials: true });

            if (response.data.success) {
                this.recommendation.tasks.filter(task => task.id == recoId)[0].abandoned = false;
            }
        },
        async updateMessage(recoId, message) {
            const response = await axios.post('vuejs/update_reco_message', {
                "reco_id": recoId,
                "message": message,
            }, { withCredentials: true });

            if (response.data.success) {
                this.recommendation.tasks.filter(task => task.id == recoId)[0].message = message;
            }
        },
    },
    persist: true,
});
