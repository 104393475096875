<template>
  <div width="100%" class="card" v-if="showCardEvolution">
    <p v-if="showGraphEvolution">
      <span class="font-bold">{{
        t("dashboard.graph_evolution.month_to_month_evolution")
      }}</span>
      |
      <a href="#" @click="fetchSessions">{{
        t("dashboard.graph_evolution.year_to_year_performance")
      }}</a>
    </p>
    <p v-if="showGraphSessions || showGraphImpressions || showGraphConversions">
      <a href="#" @click="fetchData">{{
        t("dashboard.graph_evolution.month_to_month_evolution")
      }}</a> |
      <span class="font-bold">{{
        t("dashboard.graph_evolution.year_to_year_performance")
      }}</span>
    </p>
    <apexchart
      v-if="showGraphEvolution"
      type="bar"
      height="320"
      :options="chartOptions"
      :series="series"
      data-cy="graph_evolution"
    ></apexchart>
    <div
      v-if="showGraphSessions || showGraphImpressions || showGraphConversions"
    >
      <apexchart
        v-if="showGraphSessions"
        type="bar"
        height="320"
        :options="chartSessionsOptions"
        :series="seriesSessions"
      ></apexchart>
      <apexchart
        v-if="showGraphImpressions"
        type="bar"
        height="320"
        :options="chartImpressionsOptions"
        :series="seriesImpressions"
      ></apexchart>
      <apexchart
        v-if="showGraphConversions"
        type="bar"
        height="320"
        :options="chartConversionsOptions"
        :series="seriesConversions"
      ></apexchart>
      <div class="text-center">
        <button
          class="gap-2"
          @click="fetchSessions"
          :class="[showGraphSessions ? 'bg-yellow-50' : '']"
        >
          Sessions
        </button>
        <button
          class="gap-2"
          @click="fetchImpressions"
          :class="[showGraphImpressions ? 'bg-yellow-50' : '']"
        >
          Impressions
        </button>
        <button
          class="gap-2"
          @click="fetchConversions"
          :class="[showGraphConversions ? 'bg-yellow-50' : '']"
        >
          Conversions
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useMarketStore } from "@/store/global/market";
import { storeToRefs } from "pinia";
import { useGraphEvolutionStore } from "@/store/dashboard/graphEvolution";
import { useGraphPerformanceStore } from "@/store/dashboard/graphPerformance";
import { useDashboardStore } from "@/store/dashboard/dashboardStore";
// i18n
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n({});
import * as apex_lang from "@/locales/apexchart_locales.js";
const loadLocale = async (locale) => {
  try {
    return apex_lang[locale];
  } catch (error) {
    console.error("An error occurred while fetching data: ", error);
    return apex_lang["en"];
  }
}

const marketStore = useMarketStore();
const { selectedProject, selectedMarket } = storeToRefs(marketStore);
const graphEvolutionStore = useGraphEvolutionStore();
const { additionalOptions, chartSeries } = storeToRefs(graphEvolutionStore);
const graphPerformanceStore = useGraphPerformanceStore();
const {
  additionalOptionsSessions,
  chartSessionsSeries,
  additionalOptionsImpressions,
  chartImpressionsSeries,
  additionalOptionsConversions,
  chartConversionsSeries,
} = storeToRefs(graphPerformanceStore);
const dashboardStore = useDashboardStore();

const showCardEvolution = ref(true);
const showGraphEvolution = ref(true);
const showGraphSessions = ref(false);
const showGraphImpressions = ref(false);
const showGraphConversions = ref(false);
const chartOptions = ref();
const chartSessionsOptions = ref();
const chartImpressionsOptions = ref();
const chartConversionsOptions = ref();
const series = ref();
const seriesSessions = ref();
const seriesImpressions = ref();
const seriesConversions = ref();

const baseOptions = {
  legend: {
    offsetY: 5,
    show: true,
    showForSingleSeries: true,
  },
  chart: {
    id: "evolutionChart",
    toolbar: {
      show: true,
      offsetY: -13,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
      autoSelected: "zoom",
    },
  },
  markers: {
    size: 5,
  },
  dataLabels: {
    enabled: true,
    offsetY: -12,
    style: {
      fontSize: "14px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      colors: ["#f4efef", "#f4efef", "#f4efef"],
    },
    background: {
      enabled: true,
      opacity: 0.9,
      foreColor: "#727474",
      borderColor: "#474646",
      borderRadius: 2,
      borderWidth: 1,
    },
  },
  stroke: {
    width: [3, 3, 3],
  },
  xaxis: {
    type: "datetime",
    axisBorder: {
      show: true,
      color: "#bec7e0",
    },
    axisTicks: {
      show: true,
      color: "#bec7e0",
    },
    labels: {
      datetimeUTC: false,
      datetimeFormatter: {
        year: "yyyy",
        month: "MMM 'yy",
        day: "dd MMM",
        hour: "",
      },
    },
  },
  grid: {
    position: "back",
    borderColor: "#f1f3fa",
    padding: {
      left: 30,
      right: 30,
    },
  },
  title: {
    text: "",
  },
};


const baseOptionsYearToYear = {
  chart: {
    id: "yearToYearChart",
    toolbar: {
      show: true,
      offsetY: -13,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
      autoSelected: "zoom",
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: 0,
    style: {
      fontSize: "14px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: "bold",
    },
    background: {
      enabled: true,
      padding: 0,
      opacity: 0.9,
      foreColor: "#767878",
      borderColor: "#474646",
    },
  },
  stroke: {
    width: [0, 0],
  },
  xaxis: {
    type: "category",
    axisBorder: {
      show: true,
      color: "#bec7e0",
    },
    axisTicks: {
      show: true,
      color: "#bec7e0",
    },
    tooltip: {
      enabled: true,
    },
  },
  tooltip: {
    enabled: true,
    followCursor: true,
    shared: false,
    intersect: false,
  },
  grid: {
    borderColor: "#f1f3fa",
  },
  legend: {
    offsetY: 5,
    show: true,
    showForSingleSeries: true,
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        yaxis: {
          show: false,
        },
      },
    },
  ],
};

const fetchData = async () => {
  await graphEvolutionStore
    .fetchData(selectedMarket.value, selectedProject.value)
    .then(() => {
      chartOptions.value = Object.assign(baseOptions, additionalOptions.value);
      
      series.value = chartSeries.value;

      if (chartSeries.value.length === 0) {
        showCardEvolution.value = false;
        showGraphEvolution.value = false;
      } else {
        showGraphEvolution.value = true;
        showGraphSessions.value = false;
        showGraphImpressions.value = false;
        showGraphConversions.value = false;
      }
    });
};

await fetchData();

const fetchSessions = async () => {
  await graphPerformanceStore
    .fetchDataSessions(selectedMarket.value, selectedProject.value)
    .then(() => {
      chartSessionsOptions.value = Object.assign(
        baseOptionsYearToYear,
        additionalOptionsSessions.value
      );
      seriesSessions.value = chartSessionsSeries.value;

      if (chartSessionsSeries.value.length === 0) {
        showGraphSessions.value = false;
        showGraphEvolution.value = true;
      } else {
        showGraphSessions.value = true;
        showGraphEvolution.value = false;
        showGraphImpressions.value = false;
        showGraphConversions.value = false;
      }
    });
};

const fetchImpressions = async () => {
  await graphPerformanceStore
    .fetchDataImpressions(selectedMarket.value, selectedProject.value)
    .then(() => {
      chartImpressionsOptions.value = Object.assign(
        baseOptionsYearToYear,
        additionalOptionsImpressions.value
      );
      seriesImpressions.value = chartImpressionsSeries.value;

      if (chartImpressionsSeries.value.length === 0) {
        showGraphImpressions.value = false;
      } else {
        showGraphImpressions.value = true;
        showGraphEvolution.value = false;
        showGraphSessions.value = false;
        showGraphConversions.value = false;
      }
    });
};

const fetchConversions = async () => {
  await graphPerformanceStore
    .fetchDataConversions(selectedMarket.value, selectedProject.value)
    .then(() => {
      chartConversionsOptions.value = Object.assign(
        baseOptionsYearToYear,
        additionalOptionsConversions.value
      );
      
      seriesConversions.value = chartConversionsSeries.value;

      if (chartConversionsSeries.value.length === 0) {
        showGraphConversions.value = false;
      } else {
        showGraphConversions.value = true;
        showGraphImpressions.value = false;
        showGraphEvolution.value = false;
        showGraphSessions.value = false;
      }
    });
};
const chartLang = await loadLocale(locale.value)
chartOptions.value = {...chartOptions.value,
  chart: {
    locales: [
      chartLang
    ],
    defaultLocale: locale.value,
  }
}
</script>

<style scoped>
.card {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem 0.5rem 1rem;
  border: 2px solid #eeede3;
  min-width: 250px;
  flex-grow: 1;
}
</style>
