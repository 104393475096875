import { defineStore } from 'pinia';
import axios from 'axios';
import { useNotificationStore } from "@/store/global/notification";

export const useNotificationBellStore = defineStore('notificationBell', {
    state: () => ({
        listing: [],
        isLoading: true,
    }),
    actions: {
        async fetchData(selectedMarket) {
            this.isLoading = true;
            const response = await axios.post('vuejs/get_notifications_listing', {
                "market_id": selectedMarket,
            }, { withCredentials: true })

            this.isLoading = false;
            // Push the reponse in the notifications array
            // console.log(response.data.data);
            this.listing.splice(0, this.listing.length, ...response.data.data);
            return response.data.data;
        }
    },
    persist: false
});