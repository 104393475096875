import axios from "axios";

// Setting up the base URL based on the environment
const hostname = window.location.hostname;
const environments = {
    development: ["localhost", "127.0.0.1"],
    staging: "staging.semactic.com",
    testing: "testing.semactic.com",
    production: "django.semactic.com"
};

let baseURL = "https://django.semactic.com/api/";

if (environments.development.includes(hostname)) {
    baseURL = "http://127.0.0.1:8000/api/";
} else if (hostname.includes(environments.staging)) {
    baseURL = "https://django-staging.semactic.com/api/";
} else if (hostname.includes(environments.testing)) {
    baseURL = "https://django-testing.semactic.com/api/";
}

axios.defaults.baseURL = baseURL;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const getProjectId = () => {
    const project = JSON.parse(localStorage.getItem('market'));
    if (!project) {
        return null;
    }
    return project.selectedProject;
}

const updateRole = (role) => {
    const user = JSON.parse(localStorage.getItem('auth'));

    if (user && user.role != role) {
        user.role = role;
        user.access = getRoleName(role);
        localStorage.setItem('auth', JSON.stringify(user));
        location.reload();
    }
}

const getRoleName = (role) => {
    switch (role) {
		case 262144:
			return 'super_admin'
		case 1:
			return 'admin'
		case 8192:
			return 'project manager'
		case 32768:
			return 'digital specialist'
		case 65536:
			return 'digital enabler'
		case 2048:
			return 'partner'
		default:
			return 'guest';
	}
}

axios.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {

        if (error.response.config.url === 'refresh' && error.response.data.detail === 'unauthenticated') {
            window.location.href = '/logout';
            return Promise.reject(error);
        }

        if (!isRefreshing) {
            isRefreshing = true;
            return new Promise(function(resolve, reject) {
                axios.post('refresh', {
                    "project_id": getProjectId()
                }, 
                { withCredentials: true })
                    .then(({ status, data }) => {
                        if (status === 200) {
                            axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
                            originalRequest.headers['Authorization'] = `Bearer ${data.token}`;

                            updateRole(data.role);

                            processQueue(null, data.token);
                            resolve(axios(originalRequest));
                        }
                    })
                    .catch((err) => {
                        processQueue(err, null);
                        window.location.href = '/logout';
                        reject(err);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
        } else {
            return new Promise(function(resolve, reject) {
                failedQueue.push({ resolve, reject });
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
            }).catch(err => Promise.reject(err));
        }
    }

    return Promise.reject(error);
});