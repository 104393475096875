import { defineStore } from "pinia";

export const useNotificationStore = defineStore('notification', {
	state: () => ({
		isOpen: false,
		message: '',
		type: ''
        // type can be on of these: ['success', 'warning', 'info', 'error']
	}),
	actions: {
		open(type, message) {
			this.isOpen = true
			this.type = type
			this.message = message
			setTimeout(()=> {
				this.close()
			}, 4000)
		},
		close() {
			this.isOpen = false
			this.type = ''
			this.message = ''
		}
	}, 
});
