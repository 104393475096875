<template>
  <div width="100%" class="card">
    <h2>{{t('task_mngmt.title')}}</h2>
    <div class="taskManagementContainer">
        <div id="tableContainer">
            <div id="taskTable">
                <DataTable :value="project_tasks" stripedRows tableStyle="width: 40rem">
                    <Column field="market" :header="t('market', 2)" style="text-align:center; border-radius: 8px 0 0 0;"></Column>
                    <Column field="all_tasks" :header="t('task_mngmt.all_tasks')" style="text-align:center"></Column>
                    <Column field="assigned" :header="t('assigned')" style="text-align:center"></Column>
                    <Column field="in_progress" :header="t('in_progress')" style="text-align:center"></Column>
                    <Column field="done" :header="t('done')" style="text-align:center"></Column>
                    <Column field="abandoned" :header="t('abandoned')" style="text-align:center; border-radius: 0 8px 0 0;"></Column>
                </DataTable>
            </div>
        </div>
        <div id="tasksChart">
            <apexchart type="bar" width="500" :options="tasksChartOptions" :series="tasksChartOptions.series"></apexchart>
        </div>
    </div>
    <div class="midSection">
        <h2>{{t('task_mngmt.teams')}}</h2>
        <div class="legend">
             <div class="legend-assigned">
                <div class="legend-circle-assigned"></div>
                <div>{{t('assigned')}}</div>
            </div>
            <div class="legend-inprogress">
                <div class="legend-circle-inprogress"></div>
                <div>{{t('in_progress')}}</div>
            </div>
            <div class="legend-done">
                <div class="legend-circle-done"></div>
                <div>{{t('done')}}</div>
            </div>
            <div class="legend-abandoned">
                <div class="legend-circle-abandoned"></div>
                <div>{{t('abandoned')}}</div>
            </div>
        </div>
    </div>
    <div class="teamsContainer">
        <div id="teamsChart" v-for="user in users" :key="user.user_id">
            <taskTeamChart :userData="user"/>
        </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import axios from 'axios';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import taskTeamChart from './taskTeamChart.vue';

//i18n
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import { storeToRefs } from "pinia/dist/pinia";
import { useMarketStore } from "@/store/global/market";

const marketStore = useMarketStore();
const { selectedProject, selectedMarket } = storeToRefs(marketStore);
const project_tasks = ref();
const project_tasks_total = ref([1, 1, 1, 1]);
const users = ref();

await axios.post('vuejs/project_task_table', {
    'market_id': selectedMarket.value,
    'project_id': selectedProject.value
}, { withCredentials: true })
.then(response => {
    if (response.data.success == true) {
        project_tasks.value = response.data.data
        
        const sum = response.data.total.reduce((partialSum, a) => partialSum + a, 0);
        if (sum > 0) {
            project_tasks_total.value = response.data.total
            console.log(project_tasks_total.value)
        }
    }
})

const tasksChartOptions = ref({
    chart: {
        type: 'bar',
        height: 350,
    },
    plotOptions: {
        bar: {
            distributed: true,
            borderRadius: 8,
            borderRadiusApplication: 'end'
        },
    },
    xaxis:{
        categories: [t('assigned'), t('in_progress'), t('done'), t('abandoned')],
    },
    series:[{
        name: 'Tasks',
        data: project_tasks_total.value,
    }],
    colors: ['var(--jaune-semactic)', 'var(--bleu-semactic)', 'var(--vert-semactic)', 'var(--rouge-semactic)'],
    legend: {
        show: false,
    },
});

const dashboardManagementSelectedDates = ref(localStorage.getItem('dashboardManagementSelectedDates'));

watch(dashboardManagementSelectedDates, async (newValue, oldValue) => {

    console.log(newValue);

    await axios.post('vuejs/team_task_chart', {
        'market_id': selectedMarket.value,
        'project_id': selectedProject.value,
        'date': newValue
    }, { withCredentials: true })
    .then(response => {
        if (response.data.success == true) {
            users.value = response.data.data;
        }
    })
    
},{immediate: true})

</script>

<style lang="scss" scoped>
.apexcharts-legend-text {
    padding-left: 15px;
    margin-left: -15px;
}

.card {
    border-radius: 1rem;
    padding: 1.5rem 0.5rem 1rem;
    min-width: 250px;
    flex-grow: 1;

    h2 {
        margin: 0.3rem 0 1rem;

        span {
            margin-left: 1rem;
            position: relative;
            z-index: 2;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 2.2rem;
                height: 2.2rem;
                border-radius: 50%;
                z-index: -1;
                background-size: cover;
            }
            &.gold::after {
                background-image: url('/src/assets/icon/gold-mark.svg');
            }
            &.silver::after {
                background-image: url('/src/assets/icon/silver-mark.svg');
            }
            &.cooper::after {
                background-image: url('/src/assets/icon/cooper-mark.svg');
            }
        }
    }
    .values, .statistics {
        gap: 0.7rem;
    }
    .values {
        justify-content: left;
        margin-bottom: 1rem;
        font-size: 3rem;
        font-weight: 600;

        & :first-child {
            color: #07A9DA;
        }
        & :last-child {
            font-size: 2.5rem;
        }
    }
    .statistics {
        justify-content: center;
        font-weight: 500;
        font-size: 0.8rem;
        color: #919193;

        span {
            display: inline-flex;
            margin: 5px 0 0 0;
        }

        .small {
            width: 1.0rem;
            margin: 0 5px 0 5px;
        }

        & :last-child {
            font-size: 0.7rem;
        }
    }
}

.taskManagementContainer{
    display: flex;
    gap: 3rem;
}

#tableContainer {
    display: inline-flex;
    background-color: white;
    border-radius: 15px;
}

#taskTable {
    width: 100%;
    padding: 5rem;
}

.teamsContainer{
    display: flex;
    gap: 6rem;
    flex-wrap: wrap;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    padding: 3rem;

}

#tasksChart {
    width: 100%;
    background-color: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorRed {
    color: #DA073A;
    fill: #DA073A;
}
.colorGreen {
    color: #0FD398;
    fill: #0FD398;
}

.midSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.legend{
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: #919193;
}

.legend-assigned, .legend-inprogress, .legend-done, .legend-abandoned{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.legend-circle-assigned, .legend-circle-inprogress, .legend-circle-done, .legend-circle-abandoned{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.legend-circle-assigned{ 
    background-color: var(--jaune-semactic);
}

.legend-circle-inprogress{
    background-color: var(--bleu-semactic);
 }

 .legend-circle-done{
    background-color: var(--vert-semactic);
 }

 .legend-circle-abandoned{
    background-color: var(--rouge-semactic);
 }


@media screen and (max-width: 1300px) {
    .taskManagementContainer {
        flex-direction: column;
    }
}
</style>