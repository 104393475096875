import { defineStore } from "pinia";
import axios from "axios";

export const useAuthStore = defineStore('auth', {
	state: () => ({
		user: null,
		isLoggedIn: false,
		access: 'guest',
		errorMessage: '',
		token: '',
		selector: ''
	}),
	actions: {
		async signIn(inputs) {
			try {
				this.errorMessage = ""
				const data  = await axios.post("login", inputs, {
					withCredentials: true,
					headers: { "Content-Type": "application/json" },
				});
				if (data) {
					if (data.response && data.response.status == 401) {
						this.errorMessage = "Too many attempt wait 1 minute"
					}
					else{
						axios.defaults.headers.common["Authorization"] = `Bearer ${data.data.token}`;
						await this.storeUser();
					}
				}
			} catch (error) {
				this.errorMessage = "Incorrect Email or Password"
				setTimeout(() => {
					this.errorMessage = ''
				}, 3000)
				console.error(error);
			}
		},

        async checkAccess(project_id) {
            
            const response = await axios.post('vuejs/check_access', 
            { 
                'project_id': project_id
            }, 
            { withCredentials: true });

            data = response.data;

            if (data) {
                console.log(data);
            }

            return data;
        },

		async getUser() {
			try {
				const { data } = await axios.get("user", {
					withCredentials: true,
				});
				return data;
			} catch (error) {
				console.error(error);
			}
		},

		async storeUser() {
			try {
				const response = await this.getUser();

				const user = {
					id: response.id,
					name: response.name,
					email: response.email,
					role: response.roles_mask,
					language: response.language,
					loggedInAt: Date.now(),
				};

				this.user = user;
				this.access = this.getRole();
				this.getLogged()


			} catch (error) {
				console.error(error);
			}
		},

		getLogged() {
            // monday
            axios.post('vuejs/monday', {
                user_id: this.user.id,
            }, { withCredentials: true })

			this.isLoggedIn = true;
		},

		getRole() {

			if (this.user) {
				switch (this.user.role) {
					case 262144:
						return 'super_admin'
					case 1:
						return 'admin'
					case 8192:
						return 'project manager'
					case 32768:
						return 'digital specialist'
					case 65536:
						return 'digital enabler'
					case 2048:
						return 'partner'
					default:
						return 'guest';
				}
			}
			return null;
		},

		hasAccess(neededRole) {
			
			let allowedRoles = []

			switch (this.access) {
				case 'super_admin':
					return true
			
				case 'admin':
					if (neededRole !== 'super_admin') {
						return true;
					}
					else {
						return false;
					}
				
				case 'project manager':
					allowedRoles = ['super_admin', 'admin',];
					if (!allowedRoles.includes(neededRole)) {
						return true;
					}
					else {
						return false;
					}

				case 'digital specialist':
					allowedRoles = ['super_admin', 'admin', 'digital specialist'];
					if (!allowedRoles.includes(neededRole)) {
						return true;
					}
					else {
						return false;
					}

				case 'digital enabler':
					allowedRoles = ['super_admin', 'admin', 'digital specialist', 'project manager'];
					if (!allowedRoles.includes(neededRole)) {
						return true;
					}
					else {
						return false;
					}

				case 'partner':
					allowedRoles = ['super_admin', 'admin', 'digital specialist', 'project manager'];
					if (!allowedRoles.includes(neededRole)) {
						return true;
					}
					else {
						return false;
					}
					
				default:
					return false
			}
		},

		hasUser() {
			return this.user != null;
		},

		logout() {
			this.user = null;
			this.isLoggedIn = false;
			this.access = null;
		},

		setTokenAndSelector(token, selector) {
			this.token = token;
			this.selector = selector;
		},
		
		changeLanguage(language) {
			this.user.language = language;
			console.log('language', language)
		},

        async createPassword(inputs) {

            let data = {};

            const response = await axios.post('create-password/', {
                ...inputs,
                // token: token,
                // selector: this.selector
            }, { withCredentials: true })
            .then(function(response) {
                console.log(response);
                data = {
                    success: response?.data?.success, 
                    message: response?.data?.message
                };
            })
            .catch(function(error) {
                console.log(error?.response?.status);
                if (error?.response?.status !== 200) {
                
                    console.log(error?.response?.data);
                    // return {status: error?.response?.data?.success, message: error?.response?.data?.message};
                    data = {
                        success: error?.response?.data?.success, 
                        message: error?.response?.data?.message
                    };
                }
            });

            return data;
        },

        async verifyTokenAndSelector(token, selector) {
            try {

                const response = await axios.post("validate-user-token", {
                    token: token,
                    selector: selector
                }, 
                {
                    withCredentials: true,
                    headers: { "Content-Type": "application/json" },
                });

                if (response?.status === 200) {
                    this.setTokenAndSelector(token, selector);
                }
            } catch (error) {
                console.error(error);
            }
        },
	}, 
	persist: true,
});
