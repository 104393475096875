<template>
  <div height="153.4px" width="20%" class="card">
    <h2 class="self-center">{{t('dashboard.overview_kpis.impressions')}}</h2>
    <div class="values">
      <p data-cy="kpis_impression_value">{{ kpis.impressions.toLocaleString() }}</p>
    </div>
    <div class="statistics">
      <div>
        <span>
          {{t('dashboard.overview_kpis.compared_to_previous_period')}}:
          <div class="small">
            <ComparedStatisticIcon class="self-center"
                                   :class="[kpis.impressions_previous < kpis.impressions ? 'colorGreen' : 'colorRed']"
                                   :value="kpis.impressions_previous" :comparated="kpis.impressions" />
          </div>
          {{ kpis.impressions_previous_change }} &#37;
        </span>
      </div>
      <div>
        <span>
          {{t('dashboard.overview_kpis.compared_to_previous_year')}}:
          <div class="small">
            <ComparedStatisticIcon class="self-center"
                                   :class="[kpis.impressions_year_ago < kpis.impressions ? 'colorGreen' : 'colorRed']"
                                   :value="kpis.impressions_year_ago" :comparated="kpis.impressions" />
          </div>
          {{ kpis.impressions_year_ago_change }} &#37;
        </span>
      </div>
    </div>
  </div>

  <div height="153.4px" width="20%" class="card">
    <h2 class="self-center">{{t('dashboard.overview_kpis.sessions')}}</h2>
    <div class="values">
      <p data-cy="kpis_sessions_value">{{ kpis.sessions.toLocaleString() }}</p>
    </div>
    <div class="statistics">
      <div>
          <span>
          {{t('dashboard.overview_kpis.compared_to_previous_period')}}:
          <div class="small">
            <ComparedStatisticIcon class="self-center"
                                   :class="[kpis.sessions_previous < kpis.sessions ? 'colorGreen' : 'colorRed']"
                                   :value="kpis.sessions_previous" :comparated="kpis.sessions" />
          </div>
          {{ kpis.sessions_previous_change }} &#37;
        </span>
      </div>
      <div>
          <span>
            {{t('dashboard.overview_kpis.compared_to_previous_year')}}:
            <div class="small">
              <ComparedStatisticIcon class="self-center"
                                     :class="[kpis.sessions_year_ago < kpis.sessions ? 'colorGreen' : 'colorRed']"
                                     :value="kpis.sessions_year_ago" :comparated="kpis.sessions" />
            </div>
            {{ kpis.sessions_year_ago_change }} &#37;
          </span>
      </div>
    </div>
  </div>

  <div height="153.4px" width="20%" class="card">
    <h2 class="self-center">{{t('dashboard.overview_kpis.traffic_quality')}}</h2>
    <div class="values">
      <p>
        <RatingStars :value="kpis.traffic" />
      </p>
    </div>
    <div class="statistics">
      <div>
          <span>
          {{t('dashboard.overview_kpis.compared_to_previous_period')}}:
          <div class="small">
            <ComparedStatisticIcon class="self-center"
                                   :class="[kpis.traffic_previous < kpis.traffic ? 'colorGreen' : 'colorRed']"
                                   :value="kpis.traffic_previous" :comparated="kpis.traffic" />
          </div>
          {{ kpis.traffic_previous_change }} &#37;
        </span>
      </div>
      <div>
          <span>
            {{t('dashboard.overview_kpis.compared_to_previous_year')}}:
            <div class="small">
              <ComparedStatisticIcon class="self-center"
                                     :class="[kpis.traffic_year_ago < kpis.traffic ? 'colorGreen' : 'colorRed']"
                                     :value="kpis.traffic_year_ago" :comparated="kpis.traffic" />
            </div>
            {{ kpis.traffic_year_ago_change }} &#37;
          </span>
      </div>
    </div>
  </div>

  <div height="153.4px" width="20%" class="card">
    <h2 class="self-center" v-if="kpis.tracking_type == 'revenue'">{{t('dashboard.overview_kpis.revenue')}}</h2>
    <h2 class="self-center" v-else-if="kpis.tracking_type == 'transaction'">{{t('dashboard.overview_kpis.transaction')}}</h2>
    <h2 class="self-center" v-else>{{t('dashboard.overview_kpis.conversions')}}</h2>
    <div class="values">
      <p v-if="kpis.tracking_type == 'revenue'" data-cy="kpis_revenue_value">{{ kpis.revenue.toLocaleString() }}</p>
      <p v-else-if="kpis.tracking_type == 'transaction'" data-cy="kpis_transaction_value">{{ kpis.transaction.toLocaleString() }}</p>
      <p v-else data-cy="kpis_conversion_value">{{ kpis.conversion.toLocaleString() }}</p>
    </div>
    <div class="statistics">
      <div>
        <span v-if="kpis.tracking_type == 'revenue'">
          {{t('dashboard.overview_kpis.compared_to_previous_period')}}:
          <div class="small">
            <ComparedStatisticIcon class="self-center"
                                   :class="kpis.revenue_previous < kpis.revenue ? 'colorGreen' : 'colorRed'"
                                   :value="kpis.revenue_previous" :comparated="kpis.revenue" />
          </div>
          {{ kpis.revenue_previous_change }} &#37;
        </span>
        <span v-else-if="[kpis.tracking_type == 'transaction']">
          {{t('dashboard.overview_kpis.compared_to_previous_period')}}:
          <div class="small">
            <ComparedStatisticIcon class="self-center"
                                   :class="[kpis.transaction_previous < kpis.transaction ? 'colorGreen' : 'colorRed']"
                                   :value="kpis.transaction_previous" :comparated="kpis.transaction" />
          </div>
          {{ kpis.transaction_previous_change }} &#37;
        </span>
        <span v-else>
          {{t('dashboard.overview_kpis.compared_to_previous_period')}}:
          <div class="small">
            <ComparedStatisticIcon class="self-center"
                                   :class="[kpis.conversion_previous < kpis.conversion ? 'colorGreen' : 'colorRed']"
                                   :value="kpis.conversion_previous" :comparated="kpis.conversion" />
          </div>
          {{ kpis.conversion_previous_change }} &#37;
        </span>
      </div>
      <div>
          <span v-if="kpis.tracking_type == 'revenue'">
            {{t('dashboard.overview_kpis.compared_to_previous_year')}}:
            <div class="small">
              <ComparedStatisticIcon class="self-center"
                                     :class="[kpis.revenue_year_ago < kpis.revenue ? 'colorGreen' : 'colorRed']"
                                     :value="kpis.revenue_year_ago" :comparated="kpis.revenue" />
            </div>
            {{ kpis.revenue_year_ago_change }} &#37;
          </span>
          <span v-else-if="[kpis.tracking_type == 'transaction']">
            {{t('dashboard.overview_kpis.compared_to_previous_year')}}:
            <div class="small">
              <ComparedStatisticIcon class="self-center"
                                     :class="[kpis.transaction_year_ago < kpis.transaction ? 'colorGreen' : 'colorRed']"
                                     :value="kpis.transaction_year_ago" :comparated="kpis.transaction" />
            </div>
            {{ kpis.transaction_year_ago_change }} &#37;
          </span>
          <span v-else>
            {{t('dashboard.overview_kpis.compared_to_previous_year')}}:
            <div class="small">
              <ComparedStatisticIcon class="self-center"
                                     :class="[kpis.conversion_year_ago < kpis.conversion ? 'colorGreen' : 'colorRed']"
                                     :value="kpis.conversion_year_ago" :comparated="kpis.conversion" />
            </div>
            {{ kpis.conversion_year_ago_change }} &#37;
          </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia';
import {useMarketStore} from "@/store/global/market";
import {useKpiStore} from "@/store/dashboard/kpi";
import {useDashboardStore} from "@/store/dashboard/dashboardStore";

import ComparedStatisticIcon from '../utilities/icons/comparedStatisticIcon.vue';
import RatingStars from '../utilities/icons/ratingStars.vue';
// i18n
import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n({})

const marketStore = useMarketStore();
const { selectedMarket, selectedProject } = storeToRefs(marketStore);

const kpiStore = useKpiStore();
const { getKpis } = storeToRefs(kpiStore);
const kpis = ref({});

const dashboardStore = useDashboardStore();

const selected_dates = JSON.parse(localStorage.getItem('dashboardSelectedDates'));

const fetchData = async () => {
  await kpiStore.fetchData(selectedMarket.value, selectedProject.value).then(() => {
    kpis.value = getKpis.value
  });
}

await fetchData();



</script>

<style lang="scss" scoped>
.card {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem 0.5rem 1rem;
  border: 1.5px solid #EEEDE3;
  min-width: 250px;
  flex-grow: 1;

  h2 {
    text-align: center;
    margin: 0.3rem 0 1rem;

    span {
      margin-left: 1rem;
      position: relative;
      z-index: 2;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 50%;
        z-index: -1;
        background-size: cover;
      }
      &.gold::after {
        background-image: url('/src/assets/icon/gold-mark.svg');
      }
      &.silver::after {
        background-image: url('/src/assets/icon/silver-mark.svg');
      }
      &.cooper::after {
        background-image: url('/src/assets/icon/cooper-mark.svg');
      }
    }
  }
  .values, .statistics {
    //display: flex;
    //align-items: center;
    gap: 0.7rem;
  }
  .values {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: 600;

    & :first-child {
      color: #07A9DA;
    }
    & :last-child {
      font-size: 2.5rem;
    }
  }
  .statistics {
    justify-content: center;
    font-weight: 500;
    font-size: 0.8rem;
    color: #919193;

    span {
      display: inline-flex;
      margin: 5px 0 0 0;
    }

    .small {
      width: 1.0rem;
      margin: 0 5px 0 5px;
    }

    & :last-child {
      font-size: 0.7rem;
    }
  }
}
.colorRed {
  color: #DA073A;
  fill: #DA073A;
}
.colorGreen {
  color: #0FD398;
  fill: #0FD398;
}
</style>