<template>
  <div class="dropdown" ref="dropdown">
    <div class="dropdown-selected" @click="toggleOpen">
      <input class="dropdown-filter" type="text" v-model="searchTerm" :placeholder="selectedItemName" @input="updateFilter" :data-selected-id="selectedItem">
      <i :class="['dropdown-icon', isOpen ? 'icon-up' : 'icon-down']"></i>
    </div>
    <div v-show="isOpen" class="dropdown-options">
      <div class="dropdown-options-item" ref="options">
        <div v-for="item in filteredOptions">
          <div class="item" :data-id="item.id" :class="{ 'dropdown-option-selected': selectedItem === item.id }" @click="selectOption(item)">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, onUnmounted, watchEffect, getCurrentInstance } from 'vue';
const { emit } = getCurrentInstance();

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  selected: {
    type: [String, Number],
    default: null,
  },
  width: {
    type: String,
    default: '100%',
  },
});

const selectedItem = ref(props.selected);

watchEffect(() => {
  selectedItem.value = props.selected;
});

const selectedItemName = computed(() => {
  const selectedOption = props.options.find(option => option.id == selectedItem.value);
  return selectedOption ? selectedOption.name : '';
});

const isOpen = ref(false);

function toggleOpen() {
  isOpen.value = !isOpen.value;
}

function selectOption(item) {
  selectedItem.value = item.id;
  isOpen.value = false;

  console.log(item);
  emit('changeItem', item.id);
}

const searchTerm = ref('');

const filteredOptions = computed(() => {
  return props.options.filter(option => {
    const name = option.name.toLowerCase();
    const term = searchTerm.value.toLowerCase();
    return name.includes(term);
  });
});

function updateFilter(event) {
  isOpen.value = true
  searchTerm.value = event.target.value;
}

const handleClickOutside = event => {
  if (!event.target.closest('.dropdown')) {
    isOpen.value = false;
    searchTerm.value = '';
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>


<style lang="scss" scoped>

.dropdown {
  min-width: v-bind(width);
  max-width: v-bind(width);
  position: relative;
  cursor: pointer;
  user-select: none;
  margin: 0 1.5rem 0 0;
}

.dropdown-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: white;
  padding: 8px;
  border: 1px solid black;
  border-radius: 30px;
  color: black;
  font-weight: 500;
  font-size: 0.9rem;
}

.dropdown-filter {
    width: 100%;
    padding: 4px 0 4px 1rem;
    border: none;

  &::placeholder {
    color: black;
    font-weight: 500;
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
}

.dropdown-icon {
  margin-right: 1.2rem;
  width: 16px;
  height: 16px;
  display: inline-block;
  background-image: url("https://icons.veryicon.com/png/o/miscellaneous/zol-m-station/icon-arrow-up-2.png");
  background-repeat: no-repeat;
  transform: rotate(-180deg);
  background-size: contain;
  transition: transform 0.2s ease-in-out;
}

.icon-up {
  transform: rotate(0deg);
}

.dropdown-options {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 260px;
  overflow-y: auto;
  border-radius: 9px;
  width: 100%;
  z-index: 999;
}

.dropdown-option-selected {
  background-color: #e6f7ff;
}
  .item {
    padding: 0.3rem 1rem;

    &:hover {
      background-color: #e6f7ff;
    }
  }
  </style>
