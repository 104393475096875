import { defineStore } from "pinia";
import axios from "axios";
import {toRaw} from "vue";

export const useGraphCompetitionAnalysisStore = defineStore('graphCompetitionAnalysis', {
    state: () => ({
        dataSet: {
            Client: {
                name: '',
                data: []
            },
            Comp1: {
                name: '',
                data: []
            },
            Comp2: {
                name: '',
                data: []
            },
            Comp3: {
                name: '',
                data: []
            },
            Comp4: {
                name: '',
                data: []
            },
            Comp5: {
                name: '',
                data: []
            },
            days: 0
        }
    }),
    getters: {
        chartSeries(state) {
            if (Object.keys(state.dataSet).length === 0 && state.dataSet.constructor === Object) {
                return []
            }

            if (state.dataSet.constructor !== Object) {
                return []
            }

            return [
                {
                    type: 'column',
                    name: state.dataSet.Client.name,
                    data: toRaw(state.dataSet.Client.data)
                },
                {
                    type: 'column',
                    name: state.dataSet.Comp1.name,
                    data: toRaw(state.dataSet.Comp1.data)
                },
                {
                    type: 'column',
                    name: state.dataSet.Comp2.name,
                    data: toRaw(state.dataSet.Comp2.data)
                },
                {
                    type: 'column',
                    name: state.dataSet.Comp3.name,
                    data: toRaw(state.dataSet.Comp3.data)
                },
                {
                    type: 'column',
                    name: state.dataSet.Comp4.name,
                    data: toRaw(state.dataSet.Comp4.data)
                },
                {
                    type: 'column',
                    name: state.dataSet.Comp5.name,
                    data: toRaw(state.dataSet.Comp5.data)
                }
            ]
        }
    },
    actions: {
        async fetchData(selectedMarket, selectedProject) {
            const selected_dates = JSON.parse(localStorage.getItem('dashboardSelectedDates'));

            const response = await axios.post('vuejs/competition_analysis_chart', {
                "market_id": selectedMarket,
                "project_id": selectedProject,
                "date_range_start": selected_dates.from,
                "date_range_end": selected_dates.to
            }, { withCredentials: true });

            this.dataSet = response.data.data;

            return response.data.data;
        }
    },
    persist: false
});