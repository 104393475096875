<template>
    <svg class="icon" width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M55 51.841V4.14647C55 1.86149 53.1797 0.00915527 50.9343 0.00915527L4.06568 0.00915527C1.82027 0.00915527 0 1.86149 0 4.14647V51.841C0 54.126 1.82027 55.9783 4.06568 55.9783H50.9343C53.1797 55.9783 55 54.126 55 51.841Z" fill="#020203"/>
        <path d="M27.7145 10.8883H16.9591C12.7639 10.8883 9.3623 14.3498 9.3623 18.619C9.3623 22.8882 12.7639 26.3497 16.9591 26.3497H27.8036C28.3624 26.3497 28.816 25.8881 28.816 25.3112L28.7188 11.9103C28.7188 11.3416 28.2653 10.8883 27.7064 10.8883H27.7145Z" fill="#FFC230"/>
        <path d="M38.6799 25.649C42.5176 25.649 45.6288 22.483 45.6288 18.5776C45.6288 14.6722 42.5176 11.5063 38.6799 11.5063C34.8421 11.5063 31.731 14.6722 31.731 18.5776C31.731 22.483 34.8421 25.649 38.6799 25.649Z" fill="white"/>
        <path d="M27.2846 45.1083H38.04C42.2352 45.1083 45.6368 41.6468 45.6368 37.3776C45.6368 33.1084 42.2352 29.6469 38.04 29.6469H27.1955C26.6366 29.6469 26.1831 30.1084 26.1831 30.6854L26.2803 44.0863C26.2803 44.655 26.7338 45.1083 27.2927 45.1083H27.2846Z" fill="#07A9DA"/>
        <path d="M16.3198 44.4813C20.1575 44.4813 23.2687 41.3153 23.2687 37.4099C23.2687 33.5045 20.1575 30.3386 16.3198 30.3386C12.482 30.3386 9.37085 33.5045 9.37085 37.4099C9.37085 41.3153 12.482 44.4813 16.3198 44.4813Z" fill="white"/>
    </svg>
</template>

<style lang="scss" scoped>
    .icon{
        width: 100%;
        aspect-ratio: 1/1;
    }
</style>