import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import PrimeVue from 'primevue/config';
import VueCookies from 'vue-cookies';
import Skeleton from 'primevue/skeleton';

import App from './App.vue'
import router from './router'
import './services/axios'

import 'primevue/resources/themes/tailwind-light/theme.css';
import 'primevue/resources/primevue.min.css';
import Tooltip from 'primevue/tooltip';
import 'primeicons/primeicons.css';
import ToastService from 'primevue/toastservice';

// https://vue3datepicker.com/
import VueDatePicker from '@vuepic/vue-datepicker';
import VueApexCharts from "vue3-apexcharts";
import '@vuepic/vue-datepicker/dist/main.css'

import './assets/css/global.scss'
import './assets/css/fonts.css'
import vue3GoogleLogin from 'vue3-google-login'
import hljs from 'highlight.js';
import CodeEditor from 'simple-code-editor';

import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
// import en from './locales/en.json'
// import fr from './locales/fr.yaml'

import { useAuthStore } from "@/store/global/auth";

const app = createApp(App);
app.use(PrimeVue, {
  ripple: true,
});

app.use(ToastService);
const pinia = createPinia();
app.use(pinia);

pinia.use(piniaPluginPersistedstate);


let user_locale = 'en';
const user = useAuthStore();
if (user.user && user.user.language) {
  user_locale = user.user.language;
}
	
const i18n = createI18n({
  locale: user_locale,
  useScope: 'global',
  messages,
})
app.use(i18n);

app.use(VueCookies, { 
    // options here
});

app.use(vue3GoogleLogin, {
    clientId: '520677272714-r0bsqra5jrvqedpu38961ighuh3e1r47.apps.googleusercontent.com',
    clientSecret: '0-1-yNes73PeANEkwDPDEsS4'
  })

app.use(router);

app.component('VueDatePicker', VueDatePicker);
app.component('apexchart', VueApexCharts);
app.component('Skeleton', Skeleton);
app.component('code-editor', CodeEditor);

app.directive('tooltip', Tooltip);

app.mount('#app');