import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import { useAuthStore } from "../store/global/auth";
import { useRecommendationsStore } from "@/store/tasks/recommendations";
import { useRecommendationDetailStore } from "@/store/tasks/recommendation-detail";
import { storeToRefs } from 'pinia';

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "dashboard",
			meta: {
				isAuth: true,
			},
			component: DashboardView,
		},
		{
			path: "/edit-profile",
			name: "edit-profile",
            meta: {
				isAuth: true,
			},
			component: () => import("@/views/edit_profile/EditProfileView.vue"),
		},		
		{
			path: "/login",
			name: "login",
			component: () => import("@/views/authentification/LoginView.vue"),
		},
		{
			path: "/oauth/google",
			name: "oauth-google",
			component: () => import("@/views/oauth/GoogleView.vue"),
		},
		{
			path: "/oauth/microsoft",
			name: "oauth-microsoft",
			component: () => import("@/views/oauth/MicrosoftView.vue"),
		},
		{
			path: "/tasks",
			name: "tasks",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/tasks/TasksView.vue"),
		},
		{
			path: "/content-guidance",
			name: "content-guidance",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/content-guidance/contentGuidance.vue"),
		},
		{
			path: "/tasks/:id",
			name: "tasks_level_1",
			meta: {
			  isAuth: true
			},
			component: () => import("@/views/tasks/TasksLevel1View.vue"),
			beforeEnter: async (to, from, next) => {
				const id = parseInt(to.params.id);
				const recommendationDetailStore = useRecommendationDetailStore();
				
				try {
					await recommendationDetailStore.fetchData(id);
					const { recommendation } = storeToRefs(recommendationDetailStore);
					
					if (!recommendation.value.id) {
					// Redirect to the "not-found" route if recommendation is not found
						next({
							name: 'not-found',
							params: { pathMatch: to.path.substring(1).split('/') },
							query: to.query,
							hash: to.hash,
						});
					} else {
						// Continue to the intended route
						next();
					}
				} catch (error) {
					console.error(error);
					// Handle error and possibly redirect to an error page
					next({
						name: 'not-found', // Replace with the actual error route name
					});
				}
			}
		},
        // do no delete, it's for future use
		// {
		// 	path: "/task/:id",
		// 	name: "tasks-details",
		// 	meta: {
		// 		isAuth: true
		// 	},
		// 	component: () => import("@/views/tasks/TasksDetailsView.vue"),
		// 	beforeEnter: (to) => {

		// 		const id = parseInt(to.params.id);
		// 		const recommendationsStore = useRecommendationsStore();
		// 		const { tasks } = storeToRefs(recommendationsStore);
		// 		const task = tasks.value.find((task) => task.id === id);

		// 		if (!task) {
		// 			return {
		// 				name: 'not-found',
		// 				params: { pathMatch: to.path.substring(1).split('/') },
		// 				query: to.query,
		// 				hash: to.hash,
		// 			}
		// 		}
		// 	}
		// },
		{
			path: "/visibility",
			name: "visibility",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/visibility/VisibilityView.vue"),
		},
		{
			path: "/calendar",
			name: "calendar",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/calendar/CalendarView.vue"),
		},
		{
			path: "/finder",
			name: "finder",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/finder/FinderView.vue"),
		},
		{
			path: "/users",
			name: "users",
			meta: {
				isAuth: true,
				access: 'project manager'
			},
			component: () => import("@/views/users/UsersView.vue"),
		},
		{
			path: "/clients",
			name: "clients",
			meta: {
				isAuth: true,
				access: 'admin'
			},
			component: () => import("@/views/clients/ClientsView.vue"),
		},
		{
			path: "/project",
			name: "project",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/project/ProjectView.vue"),
		},
		{
			path: "/settings",
			name: "settings",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/settings/SettingsView.vue"),
		},
		{
			path: "/api",
			name: "api",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/api/ApiView.vue"),
		},
		{
			path: "/references",
			name: "references",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/references/ReferencesView.vue"),
		},
		{
			path: "/tips",
			name: "tips",
			meta: {
				isAuth: false
			},
			component: () => import("@/views/tips/TipsView.vue"),
		},
		{
			path: "/globalsetting",
			name: "globalsetting",
			meta: {
				isAuth: true,
				access: 'super_admin'
			},
			component: () => import("@/views/globalsetting/GlobalsettingView.vue"),
		},
		{
			path: "/logout",
			name: "logout",
			component: () => import("@/views/authentification/LogoutView.vue"),
		},
		{
			path: "/:pathMatch(.*)*",
			name: "not-found",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/PageNotFound.vue"),
		},
		{
			path: "/settings/oauth/google",
			name: "settings-oauth-google",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/oauth/SettingGoogleView.vue"),
		},
		{
			path: "/global-settings/",
			name: "global-settings",
			meta: {
				isAuth: true,
				access: 'super_admin'
			},
			component: () => import("@/views/global-settings/GlobalSettingsView.vue"),
		},
		{
			path: "/keyword/:id",
			name: "keyword-details",
			meta: {
				isAuth: true
			},
			component: () => import("@/views/keyword_details/KeywordDetailsView.vue"),
		},
		{
			path: "/resetPassword",
			name: "reset-password",
			meta: {
				isAuth: false
			},
			component: () => import("@/views/resetPassword/ResetPasswordView.vue"),
		},
		{
			path: "/createPassword",
			name: "create-password",
			meta: {
				isAuth: false
			},
			component: () => import("@/views/createPassword/CreatePasswordView.vue"),
		},
	],
    linkActiveClass: 'active-link',
    linkExactActiveClass: 'exact-active-link',
});

router.beforeEach((to, from, next) => {
	const user = useAuthStore();

	if (to.meta.isAuth && !user.isLoggedIn) {
		next("/login")
	}
	else if (to.path == '/login' && user.isLoggedIn) {
		next("/")
	}
	else {
		next()
	}

	if (to.meta.access) {

		if (user.hasAccess(to.meta.access) == false) {

			history.back()
		}
	}
});

export default router;
