<template v-if="show_container">
  <div class="title">
    <h2>{{ t('theme_analysis.title') }}</h2>
    <div class="flex flex-wrap gap-3 pr-3 text-2xl font-medium" style="color: grey;">
      <div class="flex align-items-center">
        <RadioButton v-model="topFilter" @change="fetchData" inputId="topFilter1" name="top" value="10" />
        <label class="ml-2">Top 10</label>
      </div>
      <div class="flex align-items-center">
        <RadioButton v-model="topFilter" @change="fetchData" inputId="topFilter2" name="top" value="5" />
        <label class="ml-2">Top 5</label>
      </div>
      <div class="flex align-items-center">
        <RadioButton v-model="topFilter" @change="fetchData" inputId="topFilter3" name="top" value="3" />
        <label class="ml-2">Top 3</label>
      </div>
    </div>
  </div>
  <div class="card flex">
    <div id="tableContainer">
      <div id="themeAnalysisTable" style="width: 61rem">
        <DataTable :value="theme_table_data" tableStyle="width: 59rem" scrollable scrollHeight="540px" >
          <template #empty>
            <h2 class="flex justify-content-center p-5 text-2xl">No data</h2>
          </template>
          <Column field="label" :header="t('theme_analysis.col1')" style="max-width: 70px" frozen>
            <template #body="slotProps">
              <Tag :style="{backgroundColor: slotProps.data.color}">{{slotProps.data.label}}</Tag>
            </template>
          </Column>
          <Column field="volume" :header="t('theme_analysis.col2')" style="max-width: 40px" frozen></Column>
          <Column field="keywords_top" :header="keywords_top_title" style="max-width: 60px" frozen>
            <template #body="slotProps">
              <div style="display: flex">
                <div>{{slotProps.data.keywords_top_right}}</div>
                <comparatorTag :position="slotProps.data.keywords_top_left" :last_position="slotProps.data.keywords_top_right" />
              </div>
            </template>
          </Column>
          <Column field="percent_top" :header="percent_top_title" style="max-width: 70px" frozen>
            <template #body="slotProps">
              {{slotProps.data.percent_top}}%
            </template>
          </Column>
          <Column field="keywords_total" :header="t('theme_analysis.col5')" style="max-width: 40px" frozen></Column>
        </DataTable>
      </div>
      <div>
        <apexchart type="pie" width="750" :options="themeChartOptions" :series="theme_series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue"
import {storeToRefs} from "pinia";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tag from "primevue/tag";
import RadioButton from 'primevue/radiobutton';
import comparatorTag from '../visibility/table/comparatorTag.vue';

import { useMarketStore } from "@/store/global/market";
import { useGraphThemeAnalysisStore } from "@/store/dashboard/graphThemeAnalysis";
//i18n
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const marketStore = useMarketStore();
const { selectedProject, selectedMarket } = storeToRefs(marketStore);
const graphThemeAnalysisStore = useGraphThemeAnalysisStore();
const { getTableData, getChartSeries, getChartColors, getChartXAxis, filter_top } = storeToRefs(graphThemeAnalysisStore);

const show_container = ref(true);
const theme_table_data = ref();
const theme_series = ref();
const series = ref();
const topFilter = ref('10');
const keywords_top_title = ref();
const percent_top_title = ref();

const themeChartOptions = ref({
  colors: [],
  chart: {
      id: 'ThemeAnalysisChart',
      toolbar: {
          show: true,
      }
  },
  legend: {
      show: true
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'end',
    style: {
      colors: ['#fff']
    },
    offsetX: 0,
    dropShadow: {
      enabled: true
    },
  },
  yaxis: {
    show: true
  },
  labels: []
});

const fetchData = async () => {
  await graphThemeAnalysisStore.fetchData(selectedMarket.value, selectedProject.value, topFilter.value).then(() => {
    if (getTableData.value.length > 0) {
      theme_table_data.value = getTableData.value;
      keywords_top_title.value = t('theme_analysis.col3').replace(/top_value/g, filter_top.value);
      percent_top_title.value = t('theme_analysis.col4').replace(/top_value/g, filter_top.value);
      theme_series.value = getChartSeries.value;
      themeChartOptions.value.labels = getChartXAxis.value;
      themeChartOptions.value.colors = getChartColors.value;
    } else {
      show_container.value = false;
    }
  });
}

await fetchData();

</script>

<style lang="scss" scoped>
.card {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem 0.5rem 1rem;
  border: 2px solid #EEEDE3;
  min-width: 250px;
  flex-grow: 1;
}

.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;

  h1 {
    font-size: 1.7rem;
    font-weight: 500;
  }
  h2 {
    margin: 0.7rem 0 0.7rem 0;
    font-size: 1.4rem;
    font-weight: 500;
  }
  p {
    font-size: 0.85rem;
    font-weight: 500;
    font-style: italic;
    color: #919193;
    line-height: 2.3rem;
  }
}

#tableContainer {
  display: inline-flex;
}
</style>