<template>
    <aside>
        <router-link active-class="active-link" class="btn btn-primary" to="/" data-cy="sb_logo" id="logo">
            <logo_icon/>
        </router-link>
        <div class="navigation">
            <router-link active-class="active-link" class="btn btn-primary" to="/" data-cy="sb_dashboard_link" id="sb_dashboard_link">
                <dashboard_icon/>
            </router-link>
            <router-link class="btn btn-primary" to="/tasks" data-cy="sb_tasks_link" id="sb_tasks_link">
                <tasks_icon/>
            </router-link>
            <router-link class="btn btn-primary" to="/visibility" data-cy="sb_visibility_link" id="sb_visibility_link">
                <eyes_icon/>
            </router-link>
            <router-link class="btn btn-primary" to="/calendar" data-cy="sb_calendar_link" id="sb_calendar_link">
                <calendar_icon/>
            </router-link>
            <router-link class="btn btn-primary" to="/finder" data-cy="sb_finder_link" id="sb_finder_link" >
                <search_icon/>
            </router-link>
            <router-link v-if="user.hasAccess('project manager')" class="btn btn-primary" to="/users" data-cy="sb_users_link" id="sb_users_link">
                <users_icon/>
            </router-link>
            <router-link v-if="user.hasAccess('admin')" class="btn btn-primary" to="/clients" data-cy="sb_clients_link" id="sb_clients_link">
                <clients_icon/>
            </router-link>
            <!-- <router-link class="btn btn-primary" to="/api" data-cy="sb_api_link" id="sb_api_link" >
                <api_icon/>
            </router-link> -->
            <router-link v-if="user.hasAccess('project manager')" class="btn btn-primary" to="/settings" data-cy="sb_settings_link" id="sb_settings_link">
                <setting_icon/>
            </router-link>
        </div>
	</aside>
</template>

<script setup>
    import { RouterLink } from 'vue-router';
    import { useAuthStore } from "@/store/global/auth";
    import logo_icon from '../icons/svg/logo_icon.vue';
    import api_icon from '../icons/svg/api_icon.vue';
    import clients_icon from '../icons/svg/clients_icon.vue';
    import calendar_icon from '../icons/svg/calendar_icon.vue';
    import dashboard_icon from '../icons/svg/dashboard_icon.vue';
    import eyes_icon from '../icons/svg/eyes_icon.vue';
    import search_icon from '../icons/svg/search_icon.vue';
    import setting_icon from '../icons/svg/setting_icon.vue';
    import tasks_icon from '../icons/svg/tasks_icon.vue';
    import users_icon from '../icons/svg/users_icon.vue';

    const user = useAuthStore();
</script>