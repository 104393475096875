<template>
    <svg class="icon" width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4854 0.0187988C11.6325 0.0187988 10.9322 0.299441 10.4668 0.751238C10.0012 1.20301 9.7682 1.74963 9.56558 2.26493C9.16009 3.29564 8.89685 4.20082 7.9063 4.76052C6.91576 5.32022 5.98885 5.08952 4.87416 4.91793C4.31659 4.83213 3.71278 4.7547 3.08016 4.92328C2.44748 5.09208 1.84997 5.54595 1.42354 6.26881C0.997108 6.99166 0.894362 7.72619 1.06137 8.34678C1.22861 8.96714 1.5968 9.43636 1.9515 9.86583C2.6607 10.725 3.32991 11.3995 3.32991 12.5188C3.32991 13.6381 2.6607 14.3127 1.9515 15.1718C1.59679 15.6012 1.22863 16.0705 1.06161 16.6908C0.894609 17.3114 0.99736 18.0459 1.42378 18.7688C1.85021 19.4917 2.44748 19.9456 3.08016 20.1143C3.71285 20.2829 4.31682 20.2055 4.87416 20.1197C5.98909 19.9481 6.91582 19.7174 7.9063 20.2771C8.89679 20.8367 9.16009 21.7419 9.56558 22.7727C9.76844 23.2879 10.0011 23.8346 10.4668 24.2864C10.9322 24.7381 11.6325 25.0188 12.4854 25.0188C13.3382 25.0188 14.0385 24.7382 14.5039 24.2864C14.9696 23.8346 15.2023 23.288 15.4051 22.7727C15.8106 21.742 16.0738 20.8368 17.0644 20.2771C18.0549 19.7174 18.9816 19.9481 20.0965 20.1197C20.6539 20.2055 21.2579 20.2829 21.8905 20.1143C22.5232 19.9457 23.1205 19.4916 23.5469 18.7688C23.9733 18.0459 24.0761 17.3114 23.9091 16.6908C23.7421 16.0702 23.3737 15.6012 23.0192 15.1718C22.31 14.3126 21.6408 13.6381 21.6408 12.5188C21.6408 11.3995 22.31 10.7249 23.0192 9.86583C23.3739 9.43638 23.7421 8.96714 23.9093 8.34678C24.0763 7.72619 23.9736 6.99166 23.5472 6.26881C23.1207 5.54595 22.5232 5.09203 21.8905 4.92328C21.2579 4.7547 20.6541 4.83213 20.0965 4.91793C18.9819 5.08952 18.0549 5.32018 17.0644 4.76052C16.0739 4.20086 15.8106 3.2957 15.4051 2.26493C15.2025 1.74968 14.9696 1.20302 14.5039 0.751238C14.0385 0.299465 13.3382 0.0187988 12.4854 0.0187988ZM12.4854 8.35212C14.8586 8.35212 16.7977 10.2257 16.7977 12.5188C16.7977 14.8118 14.8586 16.6854 12.4854 16.6854C10.1121 16.6854 8.17299 14.8118 8.17299 12.5188C8.17299 10.2257 10.1121 8.35212 12.4854 8.35212Z" fill="currentColor"/>
    </svg>
</template>

<style lang="scss" scoped>
    .icon{
        width: 100%;
        aspect-ratio: 1/1;
    }
</style>