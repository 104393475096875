<template>
	<section id="user">
		<NoticationBell/>
		<HelpMenu/>
		<div class="profile" @click="toggleMenu">
			<avatar :href="user_image"></avatar>
			<p class="name" >{{ user_name }} <i :class="['dropdown-icon', menuOpen ? 'icon-up' : 'icon-down']"></i></p>
			<ul v-if="menuOpen" class="dropdown-menu">
				<li><router-link to="/edit-profile">{{ t('edit_profile') }}</router-link></li>
				<li v-if="user.hasAccess('super_admin') == true"><router-link to="/global-settings">{{ t('app_settings') }}</router-link></li>
				<li><router-link to="/logout">{{ t('logout') }}</router-link></li>
			</ul>
		</div>
		<!-- <select v-model="locale" @change="onLocaleChange">
			<option value="en">En</option>
			<option value="fr">Fr</option>
		</select> -->
	</section>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import NoticationBell from '../profile/NotificationBell.vue';
import { useAuthStore } from "@/store/global/auth";
import { useProfileStore } from '@/store/global/profile.js';
import avatar from './avatar.vue';
import { storeToRefs } from 'pinia';
import HelpMenu from '@/components/utilities/profile/HelpMenu.vue';
// i18n

const user = useAuthStore();
const profile = useProfileStore();
const { user_name, user_image } = storeToRefs(profile)
const { access} = storeToRefs(user)
const user_obj = await user.getUser();

window.dataLayer = window.dataLayer || [];

  // i18n
import { useI18n } from 'vue-i18n'
import VueApexCharts from 'vue-apexcharts';

const { t, locale, availableLocales } = useI18n({
    // inheritLocale: true,
    // useScope: 'global'
})
// watch(() => locale.value, (val) => {
// 	console.log(`locale changed ${val}`)
// 	localStorage.setItem('locale', val)
// })
// const emits = defineEmits(['locale-changed']);

const onLocaleChange = () => {
    // This function will be called whenever the selected item changes
    // You can put your reload logic here
    console.log('Locale changed to: ' + locale.value);
	localStorage.setItem('locale', locale.value)
	// VueApexCharts.chart.setLocale(locale.value);
	// emits('locale-changed', locale.value);
	location.reload();
};

onMounted(() => {
	
	// if (user.user && user.user.language) {
	// 	const language = user.user.language;
	// 	if (availableLocales.includes(language)) {
	// 		locale.value = language;
	// 	} else {
	// 		locale.value = 'en';
	// 	}
	// }

});

await profile.fetchData();
let menuOpen = ref(false);

onMounted(() => {
	window.dataLayer.push({ "user" : {'name' :  user_name.value, "access":  access.value, "email" : user_obj.email, "language" : user_obj.language}});
  document.addEventListener('click', closeMenu);
//   localStorage.getItem('locale') ? locale.value = localStorage.getItem('locale') : locale.value = 'en';
});

async function toggleMenu() {
  menuOpen.value = !menuOpen.value;
}

function closeMenu(event) {
  if (!event.target.closest('.profile')) {
    menuOpen.value = false;
  }
}
</script>

<style lang="scss" scoped>
#user {
	display: flex;
	align-items: center;

	.profile {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;

		.name {
			font-weight: 600;
			margin-right: 0.5rem;
			position: relative;
			display: inline-block;
		}

		.dropdown-menu {
			position: absolute;
			top: 2.5rem;
			left: 0;
			width: 100%;
			list-style: none;
			padding: 0;
			margin: 0;
			background-color: #fff;
			border: 1px solid #ccc;
			border-radius: 9px;
			z-index: 999;

			li {
				padding: 6px 1rem;

				&:first-child {
					border-radius: 9px 9px 0 0;
				}
				&:last-child {
					border-radius: 0 0 9px 9px;
				}

				&:hover {
					background-color: #e6f7ff;
				}

				a {
					width: 100%;
					list-style: none;
					display: block;
					text-decoration: none;
				}
			}
		}
	}
}
</style>
