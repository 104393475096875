//  from https://cdn.jsdelivr.net/npm/apexcharts/dist/locales/fr.json
// https://cdn.jsdelivr.net/npm/apexcharts/dist/locales/
const en = JSON.parse(`
{
  "name": "en",
  "options": {
    "months": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "shortMonths": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "days": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    "toolbar": {
      "exportToSVG": "Download SVG",
      "exportToPNG": "Download PNG",
      "exportToCSV": "Download CSV",
      "menu": "Menu",
      "selection": "Selection",
      "selectionZoom": "Selection Zoom",
      "zoomIn": "Zoom In",
      "zoomOut": "Zoom Out",
      "pan": "Panning",
      "reset": "Reset Zoom"
    }
  }
}
`)

const fr = JSON.parse(`
{
  "name": "fr",
  "options": {
    "months": [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre"
    ],
    "shortMonths": [
      "janv.",
      "févr.",
      "mars",
      "avr.",
      "mai",
      "juin",
      "juill.",
      "août",
      "sept.",
      "oct.",
      "nov.",
      "déc."
    ],
    "days": [
      "dimanche",
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi"
    ],
    "shortDays": ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    "toolbar": {
      "exportToSVG": "Télécharger au format SVG",
      "exportToPNG": "Télécharger au format PNG",
      "exportToCSV": "Télécharger au format CSV",
      "menu": "Menu",
      "selection": "Sélection",
      "selectionZoom": "Sélection et zoom",
      "zoomIn": "Zoomer",
      "zoomOut": "Dézoomer",
      "pan": "Navigation",
      "reset": "Réinitialiser le zoom"
    }
  }
}`)

const nl = JSON.parse(`
{
  "name": "nl",
  "options": {
    "months": [
      "Januari",
      "Februari",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "December"
    ],
    "shortMonths": [
      "Jan",
      "Feb",
      "Mrt",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec"
    ],
    "days": [
      "Zondag",
      "Maandag",
      "Dinsdag",
      "Woensdag",
      "Donderdag",
      "Vrijdag",
      "Zaterdag"
    ],
    "shortDays": ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
    "toolbar": {
      "exportToSVG": "Download SVG",
      "exportToPNG": "Download PNG",
      "exportToCSV": "Download CSV",
      "menu": "Menu",
      "selection": "Selectie",
      "selectionZoom": "Zoom selectie",
      "zoomIn": "Zoom in",
      "zoomOut": "Zoom out",
      "pan": "Verplaatsen",
      "reset": "Standaardwaarden"
    }
  }
}`)

export { en, fr, nl }