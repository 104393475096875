<template>
    <svg class="icon" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
        <polygon points="14.8,12.1 11.9,9.2 3.9,17.2 2.8,16.1 10.8,8.1 7.9,5.2 17.2,2.8 "/>
    </svg>
</template>

<style lang="scss" scoped>
    .icon{
        width: 100%;
        aspect-ratio: 1/1;
        fill: currentColor;
    }
</style>