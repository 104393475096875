<template>
    <svg class="icon" width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.315 22.2958L16.2178 14.1996L13.1508 17.2662L21.248 25.3623L24.315 22.2958Z" fill="currentColor"/>
        <path d="M10.5538 20.7631C16.0203 20.7631 20.4518 16.3322 20.4518 10.8664C20.4518 5.40053 16.0203 0.969604 10.5538 0.969604C5.08725 0.969604 0.655762 5.40053 0.655762 10.8664C0.655762 16.3322 5.08725 20.7631 10.5538 20.7631Z" fill="currentColor"/>
        <path d="M10.5537 17.7604C14.3618 17.7604 17.4489 14.6737 17.4489 10.866C17.4489 7.05839 14.3618 3.97168 10.5537 3.97168C6.74555 3.97168 3.65845 7.05839 3.65845 10.866C3.65845 14.6737 6.74555 17.7604 10.5537 17.7604Z" fill="#020203"/>
    </svg>
</template>

<style lang="scss" scoped>
    .icon{
        width: 100%;
        aspect-ratio: 1/1;
    }
</style>