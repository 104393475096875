import { defineStore } from "pinia";
import axios from "axios";
import { useNotificationStore } from "@/store/global/notification";

export const useKeywordsStore = defineStore("Keywords", {
  state: () => ({
    raw_keywords: [],
    count_raw_keywords: 0,

    kpi_keywords: [],
    count_kpi_keywords: 0,

    keywords: [],
    count_keywords: 0,

    labels: [],
      labelsOptional: [],
    selectedColumns: [],
    average_position_range: [],

    filterString: { value: "", type: "global", id: null},
    filterByTop: 0,
    showBranding: true,
    searchedKeywords: [],
    searchedKeywordsLoading: false,
      searchedKeywordsLoaded: false,
    querySearchText: '',
    apiKeyData: null,

    volume_percentile: [],

    loading: true,

      num_submitted: 0,
      num_unique: 0,
      num_existent: 0,
      num_duplicates: 0,
      duplicates: [],
      existent_keywords: [],
      existent_keywords_data: [],
      existent_labels: [],
      selectedMarketOptional: 0,
      marketsOptional: []
  }),
  getters: {
    top_3(state) {
      return state.kpi_keywords.filter(
        (keyword) => keyword.client.position <= 3 && keyword.client.position !== null && keyword.client.position !== 101
      ).length;
    },
    percentage_top_3(state) {
      if (state.kpi_keywords.length === 0) return "0%";
      return (
        (
          (state.kpi_keywords.filter((keyword) => keyword.client.position <= 3)
            .length /
            state.kpi_keywords.length) *
          100
        ).toFixed(0) + "%"
      );
    },
    last_top_3(state) {
      return state.kpi_keywords.filter(
        (keyword) =>
          keyword.client.last_position !== null && keyword.client.last_position !== 101 &&
          keyword.client.last_position <= 3
      ).length;
    },
    last_percentage_top_3(state) {
      if (state.kpi_keywords.length === 0) return "0%";
      return (
        (
          (state.kpi_keywords.filter(
            (keyword) => keyword.client.last_position <= 3
          ).length /
            state.kpi_keywords.length) *
          100
        ).toFixed(0) + "%"
      );
    },
    is_top_3_bigger(getters) {
      if (getters.top_3 > getters.last_top_3) {
        return true;
      }
      else if (getters.top_3 == getters.last_top_3){
        return true;
      }
      else {
        return false;
      }
    },
    evolution_top_3(getters) {
      if (getters.last_top_3 === 0) {
        if (getters.top_3 === 0) {
          return "0 - 0 | 0%";
        } else {
          return "0 - " + getters.top_3 + " | Infinity%";
        }
      }

      const percentageChange = ((getters.top_3 - getters.last_top_3) / getters.last_top_3) * 100;
      return (
        getters.last_top_3 +
        " - " +
        getters.top_3 +
        " | " +
        percentageChange.toFixed(0) +
        "%"
      );
    },

    top_5(state) {
      return state.kpi_keywords.filter(
        (keyword) => keyword.client.position <= 5 && keyword.client.position !== null && keyword.client.position !== 101
      ).length;
    },
    percentage_top_5(state) {
      if (state.kpi_keywords.length === 0) return "0%";
      return (
        (
          (state.kpi_keywords.filter((keyword) => keyword.client.position <= 5)
            .length /
            state.kpi_keywords.length) *
          100
        ).toFixed(0) + "%"
      );
    },
    last_top_5(state) {
      return state.kpi_keywords.filter(
        (keyword) =>
          keyword.client.last_position !== null && keyword.client.last_position !== 101 &&
          keyword.client.last_position <= 5
      ).length;
    },
    last_percentage_top_5(state) {
      if (state.kpi_keywords.length === 0) return "0%";
      return (
        (
          (state.kpi_keywords.filter(
            (keyword) => keyword.client.last_position <= 5
          ).length /
            state.kpi_keywords.length) *
          100
        ).toFixed(0) + "%"
      );
    },
    is_top_5_bigger(getters) {
      if (getters.top_5 > getters.last_top_5) {
        return true;
      }
      else if (getters.top_5 == getters.last_top_5){
        return true;
      }
      else {
        return false;
      }
    },
    evolution_top_5(getters) {
      if (getters.last_top_5 === 0) {
        if (getters.top_5 === 0) {
          return "0 - 0 | 0%";
        } else {
          return "0 - " + getters.top_5 + " | Infinity%";
        }
      }

      const percentageChange = ((getters.top_5 - getters.last_top_5) / getters.last_top_5) * 100;
      return (
        getters.last_top_5 +
        " - " +
        getters.top_5 +
        " | " +
        percentageChange.toFixed(0) +
        "%"
      );
    },

    top_10(state) {
      return state.kpi_keywords.filter(
        (keyword) => keyword.client.position <= 10 && keyword.client.position !== null && keyword.client.position !== 101
      ).length;
    },
    percentage_top_10(state) {
      if (state.kpi_keywords.length === 0) return "0%";
      return (
        (
          (state.kpi_keywords.filter((keyword) => keyword.client.position <= 10)
            .length /
            state.kpi_keywords.length) *
          100
        ).toFixed(0) + "%"
      );
    },
    last_top_10(state) {
      return state.kpi_keywords.filter(
        (keyword) =>
          keyword.client.last_position !== null && keyword.client.last_position !== 101 &&
          keyword.client.last_position <= 10
      ).length;
    },
    last_percentage_top_10(state) {
      if (state.kpi_keywords.length === 0) return "0%";
      return (
        (
          (state.kpi_keywords.filter(
            (keyword) => keyword.client.last_position <= 10
          ).length /
            state.kpi_keywords.length) *
          100
        ).toFixed(0) + "%"
      );
    },
    is_top_10_bigger(getters) {
      if (getters.top_10 > getters.last_top_10) {
        return true;
      }
      else if (getters.top_10 == getters.last_top_10){
        return true;
      }
      else {
        return false;
      }
    },
    evolution_top_10(getters) {
      if (getters.last_top_10 === 0) {
        if (getters.top_10 === 0) {
          return "0 - 0 | 0%";
        } else {
          return "0 - " + getters.top_10 + " | Infinity%";
        }
      }

      const percentageChange = ((getters.top_10 - getters.last_top_10) / getters.last_top_10) * 100;
      return (
        getters.last_top_10 +
        " - " +
        getters.top_10 +
        " | " +
        percentageChange.toFixed(0) +
        "%"
      );
    },

    average_position(state) {
      const filteredKeywords = state.kpi_keywords.filter(
        (keyword) => keyword.client.position !== null && keyword.client.position !== 101
      );

      if (filteredKeywords.length === 0) return 0;

      return parseInt(
        (
          filteredKeywords.reduce(
            (acc, keyword) => acc + keyword.client.position,
            0
          ) / filteredKeywords.length
        ).toFixed(0)
      );
    },

    last_average_position(state) {
      const filteredKeywords = state.kpi_keywords.filter(
        (keyword) => keyword.client.last_position !== null && keyword.client.last_position !== 101
      );

      if (filteredKeywords.length === 0) return 0;

      return parseInt(
        (
          filteredKeywords.reduce(
            (acc, keyword) => acc + keyword.client.last_position,
            0
          ) / filteredKeywords.length
        ).toFixed(0)
      );
    },

    is_average_position_bigger(getters) {
      return getters.average_position > getters.last_average_position;
    },
    evolution_average_position(getters) {
      if (getters.last_average_position === 0) {
        if (getters.average_position === 0) {
          return "0 - 0 | 0%";
        } else {
          return "0 - " + getters.average_position + " | Infinity%";
        }
      }

      const percentageChange = ((getters.average_position - getters.last_average_position) / getters.last_average_position) * 100;

      // Handle negative percentages correctly
      const average_evolution_percentage =
        percentageChange >= 0 ? percentageChange.toFixed(0) : (percentageChange * -1).toFixed(0);

      return (
        getters.last_average_position +
        " - " +
        getters.average_position +
        " | " +
        average_evolution_percentage +
        "%"
      );
    },
      getSearchLoaded(state) {
          return state.searchedKeywordsLoaded;
      },
      marketNameOptional(state) {
          const market = state.marketsOptional.find((m) => m.id === state.selectedMarketOptional);
          return market ? market.name : null;
      },
    joinedTop3(state) {
        return state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position > 3 && keyword.client.position <= 3
        );
    },
    leftTop3(state) {
        return state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position <= 3 && keyword.client.position > 3
        );
    },
    summaryJoinedTop3(state) {
        const joined = state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position > 3 && keyword.client.position <= 3
        );
        return {
            averagePosition: this._averagePosition(joined),
            sumVolume: this._sumVolume(joined),
            count: joined.length,
        };
    },
    summaryLeftTop3(state) {
        const left = state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position <= 3 && keyword.client.position > 3
        );
        return {
            averagePosition: this._averagePosition(left),
            sumVolume: this._sumVolume(left),
            count: left.length,
        };
    },
    joinedTop5(state) {
        return state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position > 5 && keyword.client.position <= 5
        );
    },
    leftTop5(state) {
        return state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position <= 5 && keyword.client.position > 5
        );
    },
    summaryJoinedTop5(state) {
        const joined = state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position > 5 && keyword.client.position <= 5
        );
        return {
            averagePosition: this._averagePosition(joined),
            sumVolume: this._sumVolume(joined),
            count: joined.length,
        };
    },
    summaryLeftTop5(state) {
        const left = state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position <= 5 && keyword.client.position > 5
        );
        return {
            averagePosition: this._averagePosition(left),
            sumVolume: this._sumVolume(left),
            count: left.length,
        };
    },
    joinedTop10(state) {
        return state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position > 10 && keyword.client.position <= 10
        );
    },
    leftTop10(state) {
        return state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position <= 10 && keyword.client.position > 10
        );
    },
    summaryJoinedTop10(state) {
        const joined = state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position > 10 && keyword.client.position <= 10
        );
        return {
            averagePosition: this._averagePosition(joined),
            sumVolume: this._sumVolume(joined),
            count: joined.length,
        };
    },
    summaryLeftTop10(state) {
        const left = state.kpi_keywords.filter((keyword) =>
            keyword.client.last_position <= 10 && keyword.client.position > 10
        );
        return {
            averagePosition: this._averagePosition(left),
            sumVolume: this._sumVolume(left),
            count: left.length,
        };
    },
  },
  actions: {
      async fetchProjectsMarkets(project_id) {
          const response = await axios.post('vuejs/get_projects_markets',
              {
                  'project_id': project_id
              },
              { withCredentials: true });

          this.marketsOptional = response.data.data.markets;

          if ((this.selectedMarketOptional === null && this.marketsOptional.length > 0)
              || this.marketsOptional.findIndex(elem => elem.id===this.selectedMarketOptional) === -1) {
              this.selectedMarketOptional = this.marketsOptional[0].id;
          }

          return response.data.data;
      },

      async fetchOptionalLabels(marketId) {
          const response = await axios.post(
              "vuejs/get_labels",
              {
                  market_id: marketId,
              },
              { withCredentials: true }
          );

          this.labelsOptional = response.data.data;

          return response.data.data;
      },

      async importLabels(marketId, sourceMarketId, labels) {
          const response = await axios.post('vuejs/import_labels', {
              'market_id': marketId,
              'source_market_id': sourceMarketId,
              'labels': labels
          },
          { withCredentials: true }
      );

          return response.data;
      },

    async getCallsCount(marketId) {
        const response = await axios.post("vuejs/get_finder_api_key", {
            'market_id': marketId
        })
        this.apiKeyData = {
            api_key: response.data.api_key,
            monthly_api_calls_recorded_kf: response.data.monthly_api_calls_recorded_kf,
            max_monthly_api_calls_kf: response.data.max_monthly_api_calls_kf
        }
    },

    async getApiKeyData(query, langAndCountry, marketId) {
        this.searchedKeywordsLoaded = false;

        if (!this.searchedKeywordsLoading) {
            this.getCallsCount(marketId).then( async (res) => {

                if (this.apiKeyData.monthly_api_calls_recorded_kf < this.apiKeyData.max_monthly_api_calls_kf) {
                    await this.searchKeywords(query, langAndCountry, this.apiKeyData.api_key)
                    await this.getCallsCount(marketId)
                } 
                else {
                    useNotificationStore().open('info', `You have 0 calls left out of ${this.apiKeyData.max_monthly_api_calls_kf} this month`)
                }
            })
        }
    },

    async searchKeywords(query, langAndCountry, api_key) {
      this.searchedKeywordsLoading = true;
      this.querySearchText = query
      let language = langAndCountry.split("-")[1].trim();
      let country = langAndCountry.split("-")[0].trim();

      await axios
        .post("vuejs/keyword_finder", {
          query: this.querySearchText,
          api_key,
          country,
          language,
        })
        .then((response) => {
          this.searchedKeywords = response.data.results;
        })
        .finally(() => {
          this.searchedKeywordsLoading = false;
          this.searchedKeywordsLoaded = true;
        });
    },

    async fetchData(marketId) {

        this.loading = true;

        // Try to fetch selected dates from localStorage
        const selected_dates = JSON.parse(localStorage.getItem("visibilitySelectedDates"));

        let startDate, endDate, date_from, date_to;

        if (selected_dates && selected_dates.from && selected_dates.to) {

            // TODO: change this because if visibilitySelectedDates is not set, it will not work and make error in the console with selected_dates.from
            const selected_dates = JSON.parse(
                localStorage.getItem("visibilitySelectedDates")
            );

            date_from = selected_dates.from
            date_to = selected_dates.to
        } else {
            // If selected_dates do not exist, set new dates
            endDate = new Date(); // Today's date
            startDate = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days before today

            date_from = startDate.toISOString().substring(0, 10).split('-');
            date_to = endDate.toISOString().substring(0, 10).split('-');

            date_from = date_from[2] + '-' + date_from[1] + '-' + date_from[0];
            date_to = date_to[2] + '-' + date_to[1] + '-' + date_to[0];
        }

        // Extract and format the day, month, and year for startDate and endDate

        this.raw_keywords = [];

        const response = await axios.post(
            "vuejs/get_keywords",
            {
                market_id: marketId,
                date_range_start: date_from,
                date_range_end: date_to,
            },
            { withCredentials: true }
        );

        // this.fetchAveragePositionRange(marketId)
        this.fetchLabels(marketId);
        this.fetchVolumePercentile(marketId);

        if (response["data"]["data"])
        {
            this.raw_keywords = response["data"]["data"];
            this.count_raw_keywords = this.raw_keywords.length;
        }

        this.keywords = this.raw_keywords;
        this.count_keywords = this.count_raw_keywords;

        this.kpi_keywords = this.raw_keywords;
        this.count_kpi_keywords = this.count_raw_keywords;

        this.filterString = { value: "", type: "global" };
        this.filterByTop = 0;

        this.loading = false;
        return response.data.data;
    },

    async fetchAveragePositionRange(marketId) {
      const selected_dates = JSON.parse(
        localStorage.getItem("visibilitySelectedDates")
      );

      const response = await axios.post(
        "vuejs/get_average_keywords",
        {
          market_id: marketId,
          date_range_start: selected_dates.from,
          date_range_end: selected_dates.to,
        },
        { withCredentials: true }
      );

      this.average_position_range = response.data.data;
      return response.data.data;
    },

    async fetchLabels(marketId) {
      const response = await axios.post(
        "vuejs/get_labels",
        {
          market_id: marketId,
        },
        { withCredentials: true }
      );

      this.labels = response.data.data;

      return response.data.data;
    },

    async fetchVolumePercentile(marketId) {
      const response = await axios.post(
        "vuejs/get_volume_percentile",
        {
          market_id: marketId,
        },
        { withCredentials: true }
      );

      this.volume_percentile = response.data.data;

      return response.data.data;
    },

    async createKeywords(marketId, keywords, labels) {

        let response;

        try {

            response = await axios.post(
                "vuejs/create_keyword",
                {
                    market_id: marketId,
                    keywords: keywords,
                    labels: labels,
                },
                { withCredentials: true }
            );
        }
        catch (error) {

            const errorMessage = error.response?.data?.message || error.message || "An unknown error occurred";
            console.error("An error occurred:", errorMessage);
            useNotificationStore().open('error', `Error: ${errorMessage}`);
            return; // Exit the function if an error occurs
        }

        if(response.status == 200) {
            let newKeywords = {
                avg_words: null,
                client: {
                    last_position: 101,
                    position: 101
                },
                client_full_url: null,
                cmp: null,
                cmp_seo: null,
                competitor1: {
                    last_position: 101,
                    position: 101,
                    name: null
                },
                competitor1_full_url:"None",
                competitor2: {
                    last_position: 101,
                    position: 101,
                    name: null
                },
                competitor2_full_url:"None",
                competitor3: {
                    last_position: 101,
                    position: 101,
                    name: null
                },
                competitor3_full_url:"None",
                competitor4: {
                    last_position: 101,
                    position: 101,
                    name: null
                },
                competitor4_full_url:"None",
                competitor5: {
                    last_position: 101,
                    position: 101,
                    name: null
                },
                competitor5_full_url:"None",
                creation_date: null,
                id: response.data.data[0].id,
                keyword: response.data.data[0].keyword,
                labels: [],
                se_results: null,
                seo_potential: 101,
                volume: null
            }

            labels.forEach(label_id => {

                let label = this.labels.find(label => label.id === label_id)
                if (label) {
                    newKeywords.labels.push(label);
                }
            });

            useNotificationStore().open('success', 'keywords created successfully');
            this.raw_keywords.push(newKeywords);
            this.keywords = this.raw_keywords;
            this.count_raw_keywords = this.raw_keywords.length;
            this.count_keywords = this.keywords.length;
        }
        else {

            let errorMessage = response.request.responseText ? JSON.parse(response.request.responseText).message : response.request.statusText;
            useNotificationStore().open('error', response.request.status + ': ' + errorMessage);
        }
    },

    updateSelectedColumns(columns) {
      this.selectedColumns = columns;
    },

    switchBranding() {
      this.showBranding = !this.showBranding;
      this.filterKeywords();
    },

    setBranding(value) {
      this.showBranding = value;
      this.filterKeywords();
    },

    setFilterByTop(value) {
      this.filterByTop = value;
      this.filterKeywords();
    },

    setFilterString(value) {
        this.filterString = value;
        this.filterKeywords();
    },

    filterKeywords() {
        const { value: search, type: searchType } = this.filterString;
        const top = this.filterByTop;
        const showBranding = this.showBranding;

        console.log({'search': search, 'searchType': searchType, 'top': top, 'showBranding': showBranding});

        // Define filter functions for different types
        const keywordFilter = (keyword) => keyword?.keyword.toLowerCase() == search.toLowerCase();
        const urlFilter = (keyword) => {
            
            // debuging example i used to fix urlFilter
            // if (keyword?.client_full_url != null && keyword?.client_full_url.toLowerCase() == search.toLowerCase()) {
            //     console.log({
            //         URL: keyword?.client_full_url.toLowerCase(), 
            //         SEARCH: search.toLowerCase()
            //     });
            // }

            return keyword?.client_full_url != null && keyword?.client_full_url.toLowerCase() == search.toLowerCase();
        };
        const labelFilter = (keyword) => keyword?.labels.some((label) => label?.name.toLowerCase() == search.toLowerCase());

        const globalFilter = (keyword) => {
            const keywordSearch = keyword?.keyword && keyword?.keyword.toLowerCase().includes(search);
            const urlSearch = keyword?.client_full_url && keyword?.client_full_url.toLowerCase().includes(search);
            const labelSearch = keyword?.labels?.some((label) => label?.name.toLowerCase().includes(search));

            return keywordSearch || urlSearch || labelSearch;
        };

        // Choose the appropriate filter function based on searchType
        let filterFunction;
        switch (searchType) {
            case 'keyword':
                filterFunction = keywordFilter;
                break;
            case 'url':
                filterFunction = urlFilter;
                break;
            case 'label':
                filterFunction = labelFilter;
                break;
            case 'global':
                filterFunction = globalFilter;
                break;
            default:
                filterFunction = () => true; // Default to a filter that allows all keywords
        }

        // Set to store unique keyword IDs
        const uniqueKeywordIds = new Set();

        const filteredKeywords = this.raw_keywords.filter((keyword) => {
            const isTopPosition = keyword.client.position <= top;
            const hasBranding = keyword.labels.some((label) => label.name === 'Branding');

            if (!showBranding && hasBranding) {
                return false;
            }

            if (search !== '' && !filterFunction(keyword)) {
                return false;
            }

            if (top !== 0 && !isTopPosition) {
                return false;
            }

            // Check if the keyword ID is already processed
            if (uniqueKeywordIds.has(keyword.id)) {
                return false; // Skip duplicates
            }

            // Add keyword ID to the Set to track uniqueness
            uniqueKeywordIds.add(keyword.id);

            return true; // Include unique keywords
        });

        // Update the keywords array
        this.keywords = filteredKeywords;
        this.count_keywords = this.keywords.length;

        // Assuming you want the same behavior for kpi_keywords as well
        this.kpi_keywords = this.keywords.filter(
            (kw) => showBranding === true || !kw.labels.some((label) => label.name === 'Branding')
        );
        this.count_kpi_keywords = this.kpi_keywords.length;
    },

    resetFilter() {
      this.filterString = { value: "", type: "global" };
      this.filterByTop = 0;
      this.filterKeywords();
    },

    setLoading(value) {
      this.loading = value;
    },

    async deleteKeywords(marketId, keywords_id) {
      await axios
        .post(
          "vuejs/delete_keywords",
          {
            keywords: keywords_id,
            market_id: marketId,
          },
          { withCredentials: true }
        )
        .then((response) => {
          window.location.reload();
        });
    },

    async assignLabels(add_labels, remove_labels) {
      const response = await axios
        .post(
          "vuejs/assign_labels",
          {
            add_labels: add_labels,
            remove_labels: remove_labels,
          },
          { withCredentials: true }
        )
        .then((response) => {
          window.location.reload();
        });
    },

    async importKeywords(marketId, keywords) {
      const response = await axios.post(
        "vuejs/import_keywords",
        {
          market_id: marketId,
          keywords: keywords,
        },
        { withCredentials: true }
      );

      return response.data;
    },

    async updateLabel(marketId, labels) {
      const response = await axios.post(
        "vuejs/update_label",
        {
          market_id: marketId,
          labels: labels,
        },
        { withCredentials: true }
      );

      return response.data;
    },

    async createLabel(marketId, labelColor, labelName, labelDescription) {
      const response = await axios.post(
        "vuejs/create_label",
        {
          market_id: marketId,
          label_color: labelColor,
          label_name: labelName,
          label_description: labelDescription,
        },
        { withCredentials: true }
      );

      this.labels.push({
        id: response.data.data,
        label: labelName,
        description: labelDescription,
        color: labelColor,
      });

      return response.data;
    },

    async import_keywords(marketId, keywords) {
      await axios
        .post(
          "vuejs/import_keywords",
          {
            market_id: marketId,
            data: keywords,
          },
          { withCredentials: true }
        )
        .then((response) => {
          window.location.reload();
        });
    },

    _averagePosition(keywords) {
        if (keywords.length === 0) return 0;
        const totalPosition = keywords.reduce((acc, keyword) => acc + keyword.client.position, 0);
        const average = totalPosition / keywords.length;
        // Use toFixed(2) to limit to two decimal places, then parse to float
        return parseFloat(average.toFixed(2));
    },
    _sumVolume(keywords) {
        return keywords.reduce((acc, keyword) => acc + keyword.volume, 0);
    },

    async bulk_keywords(marketId, keywords) {
      const response = await axios
        .post(
          "vuejs/bulk_keywords",
          {
            market_id: marketId,
            data: keywords,
          },
          { withCredentials: true }
        );

      this.num_submitted = response.data.num_submitted;
      this.num_unique = response.data.num_unique;
      this.num_existent = response.data.num_existent;
      this.num_duplicates = response.data.num_duplicates;
      this.duplicates = response.data.duplicates;
      this.existent_keywords_data = response.data.existent_keywords;
      this.existent_keywords = response.data.existent_keywords.length > 0 ? response.data.existent_keywords.join(', ') : '--';
      this.existent_labels = response.data.existent_labels;
    },

    async deleteLabel(labelsId) {   //labelsId is an array
      const response = await axios.post(
        "vuejs/delete_label",
        {
          labels_id: labelsId,
        },
        { withCredentials: true }
      );

      if (response.data.success === "success") {

        // labelsId.forEach(label_id => {

        //   // delete from labels
        //   const index = this.labels.findIndex(label => label.id === label_id)
        //   this.labels.splice(index, 1)

        //   // delete from keywords
        //   this.raw_keywords.forEach(keyword => {
        //     const index = keyword.labels.findIndex(label => label.id === label_id)
        //     keyword.labels.splice(index, 1)
        //   })
        //   this.keywords = this.raw_keywords;

        // });

        window.location.reload();
      }
    },
  },
  persist: true,
});
