import { defineStore } from 'pinia';
import axios from 'axios';
import { useDashboardStore } from './dashboardStore';

export const useKpiStore = defineStore('kpi', {
    state: () => ({
        kpis: {
            impression: 0,
            impressions_previous_change: 0,
            impressions_year_ago_change: 0,

            sessions: 0,
            sessions_previous_change: 0,
            sessions_year_ago_change: 0,

            traffic: 0,
            traffic_previous_change: 0,
            traffic_year_ago_change: 0,

            revenue: 0,
            revenue_previous_change: 0,
            revenue_year_ago_change: 0,

            transaction: 0,
            transaction_previous_change: 0,
            transaction_year_ago_change: 0,

            conversion: 0,
            conversion_previous_change: 0,
            conversion_year_ago_change: 0,

            tracking_type: '',
        }
    }),
    getters: {
        getKpis(state) {
            return state.kpis
        },
    },
    actions: {
        async fetchData(marketId, projectId) {
            const selected_dates = JSON.parse(localStorage.getItem('dashboardSelectedDates'));
            const dashboardStore = useDashboardStore();

            const response = await axios.post('vuejs/organic_kpi', {
                'market_id': marketId,
                'project_id': projectId,
                'date_range_start': selected_dates.from,
                'date_range_end': selected_dates.to,
                'filter': dashboardStore.getFilter,
            }, { withCredentials: true });

            if (response.data.success == true) {
                this.kpis.impressions = parseInt(response.data.data.impressions)
                this.kpis.sessions = parseInt(response.data.data.sessions)
                this.kpis.traffic = parseInt(response.data.data.traffic)
                this.kpis.revenue = parseInt(response.data.data.revenue)
                this.kpis.transaction = parseInt(response.data.data.transaction)
                this.kpis.conversion = parseInt(response.data.data.conversion)
                this.kpis.tracking_type = response.data.data.tracking_type

                this.kpis.impressions_previous = parseInt(response.data.data.impressions_previous)
                this.kpis.impressions_previous_change = parseInt(response.data.data.impressions_previous_change)
                this.kpis.sessions_previous = parseInt(response.data.data.sessions_previous)
                this.kpis.sessions_previous_change = parseInt(response.data.data.sessions_previous_change)
                this.kpis.traffic_previous = parseInt(response.data.data.traffic_previous)
                this.kpis.traffic_previous_change = parseInt(response.data.data.traffic_previous_change)
                this.kpis.revenue_previous = parseInt(response.data.data.revenue_previous)
                this.kpis.revenue_previous_change = parseInt(response.data.data.revenue_previous_change)
                this.kpis.transaction_previous = parseInt(response.data.data.transaction_previous)
                this.kpis.transaction_previous_change = parseInt(response.data.data.transaction_previous_change)
                this.kpis.conversion_previous = parseInt(response.data.data.conversion_previous)
                this.kpis.conversion_previous_change = parseInt(response.data.data.conversion_previous_change)

                this.kpis.impressions_year_ago = parseInt(response.data.data.impressions_year_ago)
                this.kpis.impressions_year_ago_change = parseInt(response.data.data.impressions_year_ago_change)
                this.kpis.sessions_year_ago = parseInt(response.data.data.sessions_year_ago)
                this.kpis.sessions_year_ago_change = parseInt(response.data.data.sessions_year_ago_change)
                this.kpis.traffic_year_ago = parseInt(response.data.data.traffic_year_ago)
                this.kpis.traffic_year_ago_change = parseInt(response.data.data.traffic_year_ago_change)
                this.kpis.revenue_year_ago = parseInt(response.data.data.revenue_year_ago)
                this.kpis.revenue_year_ago_change = parseInt(response.data.data.revenue_year_ago_change)
                this.kpis.transaction_year_ago = parseInt(response.data.data.transaction_year_ago)
                this.kpis.transaction_year_ago_change = parseInt(response.data.data.transaction_year_ago_change)
                this.kpis.conversion_year_ago = parseInt(response.data.data.conversion_year_ago)
                this.kpis.conversion_year_ago_change = parseInt(response.data.data.conversion_year_ago_change)

                this.kpis.tracking_type = response.data.data.tracking_type
            }
        }
    },
    persist: false
});