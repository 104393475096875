<template>
    <svg class="icon" width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.26787" cy="4.68791" r="4.32524" fill="currentColor"/>
        <path d="M11.6743 16.0417C11.6743 19.0276 9.25372 21.4482 6.26776 21.4482C3.28181 21.4482 0.861209 19.0276 0.861209 16.0417L0.86121 12.1096C0.86121 11.5939 1.27931 11.1758 1.79507 11.1758L10.7405 11.1758C11.2562 11.1758 11.6743 11.5939 11.6743 12.1096L11.6743 16.0417Z" fill="currentColor"/>
        <circle cx="18.7029" cy="4.68791" r="4.32524" fill="currentColor"/>
        <path d="M24.1094 16.0418C24.1094 19.0278 21.6888 21.4484 18.7028 21.4484C15.7169 21.4484 13.2963 19.0278 13.2963 16.0418L13.2963 12.1098C13.2963 11.594 13.7144 11.1759 14.2301 11.1759L23.1755 11.1759C23.6913 11.1759 24.1094 11.594 24.1094 12.1098L24.1094 16.0418Z" fill="currentColor"/>
    </svg>
</template>

<style lang="scss" scoped>
    .icon{
        width: 100%;
        aspect-ratio: 1/1;
    }
</style>