<template>
	<Notification />
    <Toast position="top-center" style="width: 500px;" class="text-center" />
	<div id="app">
		<section v-if="user.isLoggedIn" id="sideBar">
			<sideBar></sideBar>
		</section>
		<section id="main">
			<suspense v-if="user.isLoggedIn">
				<topMenu/>
				<template #fallback>
					<TopMenuSkeletton/>
				</template>
			</suspense>
			<RouterView/>
		</section>
	</div>
</template>

<script setup>
import { RouterView } from 'vue-router';
import sideBar from './components/utilities/layout/sideBar.vue';
import TopMenuSkeletton from './components/utilities/layout/topMenuSkeletton.vue';
import topMenu from './components/utilities/layout/topMenu.vue';
import Notification from '@/components/shared/Notification.vue';
import Toast from 'primevue/toast';


import { useAuthStore } from "./store/global/auth";
const user = useAuthStore();
window.dataLayer = window.dataLayer || [];

</script>

<style lang="scss" scoped>
	#app{
		display: flex;
		min-width: 98vw;
		max-width: 100%;
		min-height: 100vh;
		max-height: 100%;
		background-color: #F4F3EB;
	}
	#sideBar{
		width: 5%;
		min-width: 100px;
		min-height: 100vh;
	}
	#main{
		margin-right: 2rem;
		width: 92%;
		min-height: 100vh;
	}
</style>

