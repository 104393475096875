import * as pako from 'pako';

export function decompressJson(compressedBase64Str) {
    try {

        // Decode base64 (convert ascii to binary)
        var cleanedBase64Str = compressedBase64Str.replace(/\s/g, '').replace(/-/g, '+').replace(/_/g, '/');

        var strData = atob(cleanedBase64Str);

        // Convert binary string to character-number array
        var charData = strData.split('').map(function(x) { return x.charCodeAt(0); });

        // Turn number array into byte-array
        var binData = new Uint8Array(charData);

        // Pako magic
        var data = pako.inflate(binData);

        // Convert gunzipped byteArray back to ascii string efficiently for large data
        var strData = new TextDecoder("utf-8").decode(data);

        // Parse JSON string
        var jsonData = JSON.parse(strData);

        return jsonData;
    } catch (e) {
        console.error("Error decompressing data", e);
        return null;
    }
}
