export function orderArray(array, name='reco') {
    if (array.length === 0) { return []; }

    let arrayTemp = orderByBeforeAfter(array, name);

    if (arrayTemp == 'error') { 
        array = setBeforeAfter(array, name);
        array = orderByBeforeAfter(array, name);
    }

    return array;
}

function orderByBeforeAfter(array, name) {

    let arrayLenght = array.length;
    // Check if the array is empty
    if (arrayLenght === 0) {
        return [];
    } 
    else if (arrayLenght === 1) {
        return array;
    }

    // Change the key index of every item in the array into the item id
    array = mapIdAsKey(array);

    let firstElement, lastElement;

    for (let key in array) {

        if (arrayLenght > 1 && array[key][`${name}_id_before`] === array[key][`${name}_id_after`]) {
            return 'error';
        }

        if (array[key][`${name}_id_before`] === 0) {
            firstElement = array[key];
        }

        if (array[key][`${name}_id_after`] === 0) {
            lastElement = array[key];
        }
    }

    // Check if both firstElement and lastElement are stored
    if (!firstElement || !lastElement) {
        return 'error';
    }

    // Create the result array and start with the first element
    let result = [];
    let currentElement = firstElement;
    let iterationCount = 0;

    while (currentElement) {
        result.push(currentElement);
        
        // Use the `${name}_id_after` attribute to find the next element in the order
        let nextElementId = currentElement[`${name}_id_after`];
        
        // If the next element doesn't exist or we have iterated more than array's length, break out
        if (!array[nextElementId] || iterationCount > arrayLenght) {
            return 'error';
        }

        currentElement = array[nextElementId];
        iterationCount++;
        
        if (currentElement === lastElement) {
            result.push(currentElement);
            break;
        }
    }

    return result;
}

function mapIdAsKey(array) {
    let result = {};
    array.forEach(item => {
        result[item.id] = item;
    });
    return result;
}

export function setBeforeAfter(array, name='reco') {

    if (array.length === 1) {
        array[0][name + '_id_before'] = 0;
        array[0][name + '_id_after'] = 0;
    }
    else {

        for (let i = 0; i < array.length; i++) {
    
            const currentObj = array[i];
            const previousObj = array[i - 1] || null;
            const nextObj = array[i + 1] || null;
    
            currentObj[name + '_id_before'] = previousObj ? previousObj.id : 0;
            currentObj[name + '_id_after'] = nextObj ? nextObj.id : 0;
    
            // Convert the values to integers
            currentObj[name + '_id_before'] = parseInt(currentObj[name + '_id_before']);
            currentObj[name + '_id_after'] = parseInt(currentObj[name + '_id_after']);
        }
    }

    return array;
}

export function checkForChange(initialArray, finalArray) {
    let changedElements = [];

    if (initialArray.length !== finalArray.length) {
        throw new Error("Arrays must have the same length for comparison.");
    }

    initialArray = mapIdAsKey(initialArray);
    finalArray = mapIdAsKey(finalArray);

    for (const key in initialArray) {
        if (initialArray.hasOwnProperty(key)) {
            const initialObj = initialArray[key];
            const finalObj = finalArray[key];

            if (!isEqual(initialObj, finalObj)) {
                changedElements.push(finalObj);
            }
        }
    }
    return changedElements;
}

function isEqual(objA, objB) {
    return JSON.stringify(objA) === JSON.stringify(objB);
}