<template>
    <svg class="icon" width="27" height="15" viewBox="0 0 27 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4854 1.37024C5.33114 1.37024 0.990234 7.61781 0.990234 7.61781C0.990234 7.61781 5.33114 13.8654 13.4854 13.8654C21.6396 13.8654 25.9805 7.61781 25.9805 7.61781C25.9805 7.61781 21.6396 1.37024 13.4854 1.37024Z" fill="currentColor" stroke="currentColor" stroke-width="1.75" stroke-linejoin="round"/>
        <path d="M13.4853 13.8654C17.1174 13.8654 20.0617 11.0682 20.0617 7.61781C20.0617 4.16737 17.1174 1.37024 13.4853 1.37024C9.85329 1.37024 6.90894 4.16737 6.90894 7.61781C6.90894 11.0682 9.85329 13.8654 13.4853 13.8654Z" fill="#020203" stroke="currentColor" stroke-width="1.75" stroke-linejoin="round"/>
    </svg>
</template>

<style lang="scss" scoped>
    .icon{
        width: 100%;
        aspect-ratio: 1/1;
    }
</style>